<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/turmas'
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/turmas')"
              >Turmas</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Alunos da turma {{ fastTurma.sigla_turma }}</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 v-if="fastNavegacao.exibirAlunos" class="aluno_font_color">
                  Alunos da Turma
                  <span>{{ fastTurma.sigla_turma }}</span> 
                </h1>
                <h1 v-else class="aluno_font_color">
                  Turma
                  <span>{{ fastTurma.sigla_turma }}</span> 
                </h1>

                <h3 class="aluno_font_color mb-2">Curso: <a href="#" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/curso/' + fastTurma.id_secretaria_curso)">{{fastTurma.nome_curso}}</a> </h3>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- Matrículas -->
              <div class="row mt-4">             
                <transition name="slide-fade">   
                  <div v-if="fastNavegacao.exibirAlunos" class="col-12 mt-2">
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Lista de alunos
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Filtrar por nome"
                              @keyup="filtraAlunos"
                            >
                          </div> 
                        </div>
                        <div class="row">
                          <div class="col-12 table-responsive mt-2">      
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr class="text-center">
                                  <th>
                                    <small class="font-weight-bold">Id Matricula</small>
                                  </th>
                                  <th>
                                    <small class="font-weight-bold">Nome do aluno</small>
                                  </th>
                                  <th>
                                    <small class="font-weight-bold">CPF</small>
                                  </th>
                                  <th>
                                    <small class="font-weight-bold">Ativo</small>
                                  </th>
                                  <th>
                                    <small class="font-weight-bold">Tipo</small>
                                  </th>
                                  <th>
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastUsuariosAlunosFiltro.length">
                                <tr
                                  v-for="a in pageOfAlunos"
                                  :key="a.id_matricula"
                                >
                                  <td class="align-middle text-center">
                                    #{{a.id_matricula}}
                                  </td>
                                  <td class="align-middle text-center">
                                    <a
                                      :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + a.id_pessoa_aluno"        
                                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + a.id_pessoa_aluno)"                                   
                                    >
                                      {{a.nome_razao + " " + a.sobrenome_fantasia}}
                                    </a>
                                  </td>
                                  <td class="align-middle text-center">
                                    {{a.cpf_cnpj}}
                                  </td>
                                  <td class="align-middle text-center">
                                    <div v-if="a.ativo == true">
                                      Ativo
                                    </div>
                                    <div v-else>
                                      Desativado
                                    </div>
                                  </td>
                                  <td class="align-middle text-center">
                                    <div v-if="a.matricula_por_programa == false && a.matricula_por_unidade == false">
                                      Curso Inteiro
                                    </div>
                                    <div v-else-if="a.matricula_por_programa == true ">
                                      Módulos
                                    </div>
                                    <div v-if="a.matricula_por_unidade == true">
                                      Unidades
                                    </div>
                                  </td>
                                  <td class="text-center">
                                    <button @click="exibeEstatisticasAluno(a)" class="btn btn-sm btn-primary pb-0 pt-0 mr-1">
                                      <small>Estatísticas</small>
                                    </button>
                                    <button v-if="fastTurma.finalizada" @click="exibeBoletimAluno(a)" class="btn btn-sm btn-primary pb-0 pt-0 mr-1">
                                      <small>Boletim</small>
                                    </button>
                                    <button v-else @click="exibeToasty('A turma precisa estar finalizada', 'error')" class="btn btn-sm btn-secondary pb-0 pt-0 mr-1">
                                      <small>Boletim</small>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>                            
                              <tbody v-else-if="fastUsuariosAlunosLoading">
                                <tr>
                                  <td colspan="6" class="text-center">
                                    <b-icon
                                      icon="gear-fill"
                                      animation="spin"
                                    /> Carregando alunos
                                  </td>
                                </tr>                              
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td colspan="6" class="text-center">
                                    Nenhum aluno encontrado
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-12 text-center mt-4">
                            <Pagination
                              :page-size="10"
                              :items="fastUsuariosAlunosFiltro"
                              @changePage="pageOfAlunos = $event"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">   
                  <div v-if="fastNavegacao.exibirEstatisticas" class="col-12 mt-2">
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Estatísticas do aluno 
                              <span v-if="fastAluno.nome_razao">
                                {{fastAluno.nome_razao + " " + fastAluno.sobrenome_fantasia}}
                              </span>
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right text-xs font-weight-bold text-info">
                            <span>Matriculado</span> 
                            <span v-if="!fastAluno.matricula_por_programa && !fastAluno.matricula_por_unidade">
                              no curso inteiro
                            </span>
                            <span v-else-if="fastAluno.matricula_por_programa">
                              em módulo(s)
                            </span>
                            <span v-if="fastAluno.matricula_por_unidade">
                              em unidade(s)
                            </span> 
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row no-gutters">
                          <div class="col">
                            <div class="h5 font-weight-bold text-secondary">                                              
                              <span v-if="fastAluno.nome_razao">
                                <a :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + fastAluno.id_pessoa_aluno" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + fastAluno.id_pessoa_aluno)">
                                  {{fastAluno.nome_razao + " " + fastAluno.sobrenome_fantasia}}
                                </a>  
                              </span>     
                              <span v-else>Nome não registrado</span>  
                            </div>
                            <div class="text-xs font-weight-bold text-secondary mb-1">
                              <span>CPF {{fastAluno.cpf_cnpj}}</span>  
                            </div>
                            <div class="text-xs font-weight-bold text-secondary mb-1">
                              <span>Matrícula #{{fastAluno.id_matricula}}</span>  
                            </div>   
                                             
                          </div>
                          <div class="col text-right">
                            <div class="text-xs font-weight-bold text-secondary mb-1">
                              <button v-if="fastNavegacaoEstatisticas.exibirModulos" class="btn btn-sm btn-primary" @click="exibeModalExibirCalendarioHistoricoFiltro(fastTurma.id_turma)">
                                <small><b-icon-info-circle /> Histórico de eventos na turma</small>
                              </button>
                              <button v-else-if="fastNavegacaoEstatisticas.exibirModulo" class="btn btn-sm btn-primary" @click="exibeModalExibirCalendarioHistoricoFiltro(fastTurma.id_turma, fastAlunoModulo.id_programa_turma)">
                                <small><b-icon-info-circle /> Histórico de eventos no módulo</small>
                              </button>
                              <button v-else-if="fastNavegacaoEstatisticas.exibirUnidade" class="btn btn-sm btn-primary" @click="exibeModalExibirCalendarioHistoricoFiltro(fastTurma.id_turma, fastAlunoModulo.id_programa_turma, fastAlunoUnidade.id_programa_turma_unidade)">
                                <small><b-icon-info-circle /> Histórico de eventos na unidade</small>
                              </button>
                            </div>  
                          </div>
                        </div>

                        <transition name="slide-fade"> 
                        <div v-if="fastNavegacaoEstatisticas.exibirModulos" class="row mt-4">
                          <div class="col-12">
                            <div class="row mb-4">
                              <div class="col-12 text-center text-secondary">
                                <h4>Módulos do curso ({{fastAlunoModulos.total.length}})</h4>
                              </div>
                            </div>
                            <div v-if="fastAlunoModulos.loading" class="row">
                              <div class="col-12 text-center">
                                <b-icon icon="gear-fill" animation="spin" /> Carregando módulos
                              </div>
                            </div>
                            <div v-else-if="!fastAlunoModulos.total.length" class="row">
                              <div class="col-12 text-center">
                                Nenhum módulo encontrado
                              </div>
                            </div>
                            <div v-else class="row">
                              <div v-for="(m, index) in fastAlunoModulos.total" :key="index" class="col-sm-12 col-md-12 col-lg-3 text-center mb-4">
                                <div
                                  class="fast-cursor-pointer p-4 rounded border text-info"
                                  @click="m.data_inicio_aluno ? exibirDetalhesModulo(m) : exibeToasty('Não existem dados do aluno nesse módulo', 'error')"
                                >
                                  <img
                                      class="card-img-top"
                                      :src="imageSitePlataformaImg"
                                      alt="Card image cap"
                                    >
                                  <h6 class="mt-2">
                                    {{m.sigla_programa}}
                                  </h6>
                                  <div class="text-left text-secondary">
                                    <small class="d-block">Data início: 
                                      <span v-if="m.data_inicio_aluno">{{formataDataT(m.data_inicio_aluno)}}</span> 
                                      <span v-else class="text-danger">Não iniciou</span>
                                    </small>
                                    <small class="d-block">Unidades finalizadas: 
                                      <span>{{m.cursos_finalizados}}/{{m.total_cursos}}</span> 
                                    </small>
                                  </div>  
                                  <div
                                    v-if="m.total_cursos"
                                    :class="'progress-bar progress-bar-striped progress-bar-animated bg-success'"
                                    role="progressbar"
                                    :aria-valuenow="{ width: (m.cursos_finalizados * 100) / m.total_cursos }"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    :style="{ width: (m.cursos_finalizados * 100) / m.total_cursos + '%' }"
                                  >
                                    <small class="m-0">
                                      {{ parseFloat((m.cursos_finalizados * 100) / m.total_cursos).toFixed(2) + '%'}}
                                    </small>
                                  </div>                              
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        </transition>

                        <transition name="slide-fade"> 
                        <div v-if="fastNavegacaoEstatisticas.exibirModulo" class="row mt-4">
                          <div class="col-12 text-secondary">
                            <h4>Módulo - 
                              <a 
                              :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/curso/' + fastTurma.id_secretaria_curso + '/programa/' + fastAlunoModulo.id_secretaria_curso_programa"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/curso/' + fastTurma.id_secretaria_curso + '/programa/' + fastAlunoModulo.id_secretaria_curso_programa)"
                              >
                                {{fastAlunoModulo.sigla_programa}}
                              </a> 
                            </h4>
                            <small class="d-block">Data início: 
                              <span v-if="fastAlunoModulo.data_inicio_aluno">{{formataDataT(fastAlunoModulo.data_inicio_aluno)}}</span> 
                              <span v-else class="text-danger">Não iniciou</span>
                            </small>
                            <small class="d-block">Unidades finalizadas: 
                              <span>{{fastAlunoModulo.cursos_finalizados}}/{{fastAlunoModulo.total_cursos}}</span> 
                            </small>
                            <div
                              v-if="fastAlunoModulo.total_cursos"
                              :class="'progress-bar progress-bar-striped progress-bar-animated bg-success'"
                              role="progressbar"
                              :aria-valuenow="{ width: (fastAlunoModulo.cursos_finalizados * 100) / fastAlunoModulo.total_cursos }"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              :style="{ width: (fastAlunoModulo.cursos_finalizados * 100) / fastAlunoModulo.total_cursos + '%' }"
                            >
                              <small class="m-0">
                                {{ parseFloat((fastAlunoModulo.cursos_finalizados * 100) / fastAlunoModulo.total_cursos).toFixed(2) + '%'}}
                              </small>
                            </div>
                          </div>
                          <div class="col-12 mt-4 mb-2 text-center text-secondary">
                            <h4>Unidades curriculares ({{fastAlunoUnidades.total.length}})</h4>
                          </div>
                          <div class="col-12">
                            <div v-if="fastAlunoUnidades.loading" class="row text-center">
                              <div class="col-12 text-center">
                                <b-icon icon="gear-fill" animation="spin" /> Carregando unidades curriculares
                              </div>
                          </div>
                          <div v-else-if="!fastAlunoUnidades.total.length" class="row text-center">
                            <div class="col-12 text-center">
                              Nenhuma unidade curricular encontrada
                            </div>
                          </div>
                          <div v-else class="row text-center">
                            <div v-for="(u, index) in fastAlunoUnidades.total" :key="index" class="col-sm-12 col-md-12 col-lg-4 text-center mb-2">
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-info"
                                @click="exibirDetalhesUnidade(u)"
                              >
                                <img v-if="u.logo_curso" class="card-img-top" :src="ajustaLinkApiAntiga(u.logo_curso)" alt="Card image cap">
                                <img v-else-if="u.logo_curso_mae" class="card-img-top" :src="ajustaLinkApiAntiga(u.logo_curso_mae)" alt="Card image cap">
                                <img v-else class="card-img-top" :src="imageSitePlataformaImg" alt="Card image cap">
                                <h6 class="mt-2 text-left">
                                  {{u.sequencia}} - {{u.nome_curso ? u.nome_curso : u.nome_curso_mae}}
                                </h6>
                                <div class="text-left text-secondary">
                                  <small class="d-block">Data início: 
                                    <span v-if="u.data_inicio_uc">{{formataDataT(u.data_inicio_uc)}}</span> 
                                    <span v-else class="text-danger">Não iniciou</span>
                                  </small>
                                  <small class="d-none">Data fim: 
                                    <span v-if="u.data_fim_uc">{{formataDataT(u.data_fim_uc)}}</span> 
                                    <span v-else class="text-danger">Não registrado</span>
                                  </small>
                                  <small class="d-block">Conteúdo encerrado: 
                                    <b-badge v-if="u.encerrado" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                  </small>
                                  <small v-if="u.avaliacao_configurada && u.id_secretaria_avaliacao_principal" class="d-block">Avaliação: 
                                    <b-badge v-if="u.id_secretaria_avaliacao_aluno_avaliacao" variant="success">Realizada</b-badge>
                                    <b-badge v-else variant="warning" class="text-white">Pendente</b-badge>
                                  </small>
                                  <small v-if="u.recuperacao_configurada && u.id_secretaria_avaliacao_recuperacao" class="d-block">Recuperação: 
                                    <b-badge v-if="u.id_secretaria_recuperacao_aluno_avaliacao" variant="success">Realizada</b-badge>
                                    <b-badge v-else variant="warning" class="text-white">Pendente</b-badge>
                                  </small>
                                </div>                                
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 mt-2">
                              <a
                                class="btn btn-secondary"
                                href="#"
                                @click.prevent="fastNavegacaoEstatisticas.exibirModulo = false; fastNavegacaoEstatisticas.exibirModulos = true"  
                              >
                                <b-icon-arrow-return-left />
                                Voltar
                              </a>
                            </div>
                          </div>
                          </div>
                          
                        </div>
                        </transition>

                        <transition name="slide-fade"> 
                        <div v-if="fastNavegacaoEstatisticas.exibirUnidade" class="row mt-4">
                          <div class="col-sm-12 col-md-12 col-lg-4 text-secondary font-weight-bold">
                            <span>Módulo - 
                              <a 
                              :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/curso/' + fastTurma.id_secretaria_curso + '/programa/' + fastAlunoModulo.id_secretaria_curso_programa"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/curso/' + fastTurma.id_secretaria_curso + '/programa/' + fastAlunoModulo.id_secretaria_curso_programa)"
                              >
                                {{fastAlunoModulo.sigla_programa}}
                              </a> 
                            </span>
                          </div>

                          <div class="col-sm-12 col-md-12 col-lg-8 text-secondary font-weight-bold">
                            <span>Unidade curricular - <a :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + fastAlunoUnidade.id_curso" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + fastAlunoUnidade.id_curso)">{{fastAlunoUnidade.nome_curso ? fastAlunoUnidade.nome_curso : fastAlunoUnidade.nome_curso_mae}}</a> </span>
                            <button v-if="verificaAulasFinalizadas(fastUsuarioCurso.total, 'A') && !fastAlunoUnidade.encerrado" @click="exibeModalFinalizarUnidade(fastAlunoUnidade, 'A')" class="btn btn-danger btn-sm ml-2 pt-0 pb-0">
                              <small>Finalizar</small>
                            </button> 
                          </div>
                                                   
                          <div class="col-12 mt-2">
                            <div class="row">
                              <div class="col-12 mt-2">
                                <h6>Aulas da unidade curricular ({{fastUsuarioCurso.filtro.length}})</h6>
                              </div>
                              <div class="col-12">
                                <div
                                  :class="'progress-bar progress-bar-striped progress-bar-animated bg-success'"
                                  role="progressbar"
                                  :aria-valuenow="{ width: fastUnidadePorcentagem }"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  :style="{ width: fastUnidadePorcentagem + '%' }"
                                >
                                  <small class="m-0">
                                    {{ parseFloat(fastUnidadePorcentagem).toFixed(2) + '%'}}
                                  </small>
                                </div>
                              </div> 

                              <div v-if="fastUsuarioCurso.loading" class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center">
                                <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Carregando aulas
                              </div>
                              <div v-else-if="!fastUsuarioCurso.filtro.length" class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center">
                                  <p>Nenhuma aula identificada</p>
                              </div>
                              <div v-else class="col-12">
                                <div class="row">
                                  <div v-for="(aula, index) in fastUsuarioCurso.paginacao" :key="index" class="col-sm-12 col-md-12 col-lg-12 mt-2">
                                    <b-card  header-tag="header" footer-tag="footer">
                                      <template #header>
                                        <h4 class="mb-0">
                                          Aula  {{aula.sequencia_aula}}
                                          <a v-if="aula.aula_replicada" href="#" class="text-danger ml-2" @click="exibeModalExibirAulasReplicadas(aula)">
                                            <b-icon icon="exclamation-circle-fill" variant="danger" ></b-icon> <span>Replicada</span> 
                                          </a> 
                                        </h4>
                                      </template>
                                      <b-card-text>
                                        <div>
                                          <h4> 
                                            <a href="#" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + fastAlunoUnidade.id_curso + '/aulas?aula=' + aula.id_aula)">{{aula.titulo_aula}}</a>
                                            
                                          </h4>
                                        </div>
                                        <div class="mb-2">
                                          {{aula.detalhe_aula}}
                                        </div>
                                        <div v-if="aula.data_inicio">
                                          Data de início: {{formataDataHoraT(aula.data_inicio)}}
                                        </div>
                                        <div v-if="aula.data_fim">
                                          Data de finalização: {{formataDataHoraT(aula.data_fim)}}
                                        </div>
                                        <div v-if="aula.count">
                                          Realizou <b-badge variant="secondary">{{aula.count}}</b-badge> veze(s)
                                        </div>
                                        <div class="mt-2">
                                          <b-badge v-if="!aula.status" variant="secondary">Aula não iniciada</b-badge>
                                          <b-badge v-else-if="aula.status == 'F'" variant="success">Aula finalizada</b-badge>                                 
                                          <b-badge v-else-if="aula.status == 'E'" variant="warning">Aula pendente</b-badge>
                                          <b-badge v-else variant="danger">Status não identificado</b-badge>
                                        </div>   
                                        <div v-if="verificaAtividadesFinalizadas(aula.atividades.total)" class="mt-2">
                                          <button v-if="aula.status != 'F'" @click="exibeModalFinalizarAula(aula, 'A')" class="btn btn-danger btn-sm mt-2 pt-0 pb-0">
                                            <small>Finalizar aula</small>
                                          </button>  
                                        </div>   
                                        <div class="mt-2 text-center">
                                          <button v-if="aula.atividades.loading" @click="getUsuarioCursoAulaAtividades($route.params.id_plataforma, fastAluno.id_usuario, aula.id_aula, 'A')" class="btn btn-sm btn-primary"><b-icon-eye-fill /> Exibir atividades</button>
                                          <div v-else-if="!aula.atividades.total.length" class="col-12 text-center">
                                            <p>Nenhum atividade identificada</p>
                                          </div>
                                          <div v-else class="col-12 mt-2">
                                            <div class="row mt-4">
                                              <h6>Lista de atividades ({{aula.atividades.total.length}})</h6>
                                            </div>
                                            <div class="row mt-2 text-left">
                                              <table class="table table-striped table-bordered">
                                                <thead>
                                                  <tr class="bg-dark text-white">
                                                    <th
                                                      style="width: 50px"
                                                      scope="col"
                                                    >
                                                      Sequência
                                                    </th>
                                                    <th scope="col">
                                                      Título da atividade
                                                    </th>
                                                    <th
                                                      class="text-center"
                                                      scope="col"
                                                    >
                                                      Tipo
                                                    </th>
                                                    <th
                                                      class="text-center"
                                                      scope="col"
                                                    >
                                                      Data início
                                                    </th>
                                                    <th
                                                      class="text-center"
                                                      scope="col"
                                                    >
                                                      Data fim
                                                    </th>
                                                    <th
                                                      class="text-center"
                                                      scope="col"
                                                    >
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr v-for="(atividade, index2) in aula.atividades.total" :key="index2">
                                                    <td class="text-center">
                                                      {{ atividade.sequencia_aula }}
                                                    </td>
                                                    <td class="text-left">
                                                      {{ atividade.titulo_atividade }}
                                                    </td>
                                                    <td class="text-center">
                                                      {{ formataTipoAtividade(atividade.tipo_atividade) }}
                                                    </td>                                                    
                                                    <td class="text-center">
                                                      {{ formataDataHoraT(atividade.data_inicio) }}
                                                    </td>
                                                    <td class="text-center">
                                                      {{ formataDataHoraT(atividade.data_fim) }}
                                                    </td>
                                                    <td class="text-center">
                                                      <a
                                                        class="btn btn-primary btn-sm mt-2 ml-2"
                                                        :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + fastAlunoUnidade.id_curso + '/aulas/' + aula.id_aula + '/atividade/' + atividade.id_atividade"
                                                        @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + fastAlunoUnidade.id_curso + '/aulas/' + aula.id_aula + '/atividade/' + atividade.id_atividade)"
                                                      >
                                                        <small>
                                                          <i
                                                            class="fa fa-cog"
                                                            aria-hidden="true"
                                                          />
                                                          &nbsp; Acessar
                                                        </small>
                                                      </a>
                                                      <button v-if="atividade.atividade_replicada" class="btn btn-danger btn-sm mt-2 ml-2" @click="exibeModalExibirAtividadesReplicadas(atividade)">
                                                        <small>
                                                          <b-icon icon="exclamation-circle-fill"></b-icon> Replicada
                                                        </small>                                                        
                                                      </button>
                                                      <button v-else-if="!atividade.data_fim && atividade.data_inicio" @click="exibeModalFinalizarAtividade(atividade, 'A')" class="btn btn-danger btn-sm mt-2 ml-2">
                                                        <small>Finalizar</small>
                                                      </button>
                                                      
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>                                  
                                      </b-card-text>
                                    </b-card>
                                  </div>
                                  <div class="col-12 text-center mt-2">
                                    <Pagination
                                      :page-size="1"
                                      :items="fastUsuarioCurso.filtro"
                                      @changePage="fastUsuarioCurso.paginacao = $event"
                                    />
                                  </div>
                                </div>                                 
                              </div>

                            </div>
                          </div>
                          <div v-if="fastAlunoUnidade.avaliacao_configurada && fastAlunoUnidade.id_secretaria_avaliacao_principal" class="col-12 mt-2">
                            
                            <div class="row mt-4">
                              <div class="col-12 mt-2">
                                <h6>Avaliação principal</h6>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
                                <b-list-group>

                                  <b-list-group-item class="d-flex align-items-center p-4">
                                    <b-icon-info-circle class="mr-4" scale="2" />
                                    <span class="mr-auto">Data da realização</span>
                                    <h5 v-if="fastAlunoUnidade.id_secretaria_avaliacao_aluno_avaliacao"><b-badge>{{formataDataT(fastAlunoUnidade.data_fim_avaliacao)}}</b-badge></h5>
                                    <h5 v-else><b-badge>Nunca</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastAvaliacaoConfiguracao.nota_maxima" class="d-flex align-items-center p-4">
                                    <b-icon-emoji-sunglasses class="mr-4" scale="2" />
                                    <span class="mr-auto">Nota máxima</span>
                                    <h5><b-badge>{{ fastAvaliacaoConfiguracao.nota_maxima }}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastAvaliacaoConfiguracao.media_aprovacao" class="d-flex align-items-center p-4">
                                    <b-icon-emoji-smile class="mr-4" scale="2" />
                                    <span class="mr-auto">Média para aprovação</span>
                                    <h5><b-badge>{{ fastAvaliacaoConfiguracao.media_aprovacao }}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item class="d-flex align-items-center p-4">
                                    <b-icon-graph-up class="mr-4" scale="2" />
                                    <span class="mr-auto">Peso da avaliação</span>
                                    <h5><b-badge>{{ fastAvaliacaoPeso ? fastAvaliacaoPeso : "Sem peso" }}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item class="d-flex align-items-center p-4">
                                    <b-icon-info-circle class="mr-4" scale="2" />
                                    <span class="mr-auto">Tentativas máximas</span>
                                    <h5 v-if="fastAlunoUnidade.id_secretaria_avaliacao_aluno_avaliacao"><b-badge>{{ fastAlunoUnidade.tentativas_maxima_avaliacao_aluno ? fastAlunoUnidade.tentativas_maxima_avaliacao_aluno : "ilimitada" }}</b-badge></h5>
                                    <h5 v-else><b-badge>{{ fastAlunoUnidade.tentativas_maxima_avaliacao ? fastAlunoUnidade.tentativas_maxima_avaliacao : "ilimitada" }}</b-badge></h5>
                                    
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastAlunoUnidade.id_secretaria_avaliacao_aluno_avaliacao" class="d-flex align-items-center p-4">
                                    <b-icon-info-circle class="mr-4" scale="2" />
                                    <span class="mr-auto">Tentativas realizadas</span>
                                    <h5><b-badge>{{ fastAlunoUnidade.count_avaliacao ? fastAlunoUnidade.count_avaliacao : "0" }}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastAvaliacaoPeso && fastAlunoUnidade.id_secretaria_avaliacao_aluno_avaliacao" class="d-flex align-items-center p-4">
                                    <b-icon-ui-checks class="mr-4" scale="2" />
                                    <span class="mr-auto">Nota atingida</span>
                                    <h5><b-badge>{{fastAlunoUnidade.nota_final_avaliacao}}</b-badge></h5>

                                    <h5 v-if="fastNotaAvaliacaoArredondada && fastNotaAvaliacaoArredondada > fastNotaAvaliacao"><b-badge variant="success" class="ml-1"><b-icon-arrow-up /> {{fastNotaAvaliacaoArredondada}}</b-badge></h5>
                                    <h5 v-else-if="fastNotaAvaliacaoArredondada && fastNotaAvaliacaoArredondada < fastNotaAvaliacao"><b-badge variant="danger" class="ml-1"><b-icon-arrow-down /> {{fastNotaAvaliacaoArredondada}}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastAlunoUnidade.id_secretaria_avaliacao_aluno_avaliacao" class="d-flex align-items-center p-4">
                                    <b-icon-info-circle class="mr-4" scale="2" />
                                    <span class="mr-auto">Aprovado</span>
                                    <h5 v-if="fastAlunoUnidade.aprovado_avaliacao"><b-badge variant="success">Sim</b-badge></h5>
                                    <h5 v-else><b-badge variant="danger">Não</b-badge></h5>                                    
                                  </b-list-group-item>

                                </b-list-group>
                              </div>

                              <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
                                <b-list-group>
                                  <b-list-group-item>
                                      <div
                                        v-if="chartAvaliacaoVisivel"
                                        class="d-lg-flex justify-content-center"
                                      >
                                        <div class="d-flex justify-content-center">
                                          <VueApexCharts
                                            width="380"
                                            :options="chartOptionsAvaliacaoPeso"
                                            :series="chartDataAvaliacaoPeso"
                                          />
                                        </div>   
                                      </div>
                                      <div
                                        v-if="chartAvaliacaoVisivel"
                                        class="d-lg-flex justify-content-center"
                                      >                                  
                                        <div class="d-flex justify-content-center">
                                          <VueApexCharts
                                            width="380"
                                            :options="chartOptionsAvaliacao"
                                            :series="chartDataAvaliacao"
                                          />
                                        </div>
                                      </div>
                                      <div v-else class="col-12 text-center">
                                        Sem dados estatísticos
                                      </div>
                                      
                                  </b-list-group-item>
                                </b-list-group>

                                <div v-if="fastAlunoUnidade.id_secretaria_avaliacao_aluno_avaliacao" class="mt-4">
                                  <button @click="showModal('modalEditarAvaliacao')" class="btn btn-primary m-1"> <small><b-icon-gear-fill /> alterar dados</small></button>
                                    <span
                                    class="btn btn-primary m-1"
                                    @click="showModal('modalRespostasAvaliacao')"
                                  >
                                    <small><b-icon-eye-fill /> Ver respostas</small>
                                  </span>
                                  <a      
                                    v-if="fastGabaritoAvaliacao"                            
                                    :href="ajustaLinkApiAntiga(fastGabaritoAvaliacao)"
                                    target="_blank"
                                    class="btn btn-success m-1"
                                  >
                                    <small><b-icon-download /> Gabarito assinado</small>
                                  </a>
                                  <span
                                    v-else
                                    class="btn btn-secondary disabled m-1"
                                  >
                                    <small>Gabarito assinado</small>
                                  </span>
                                  <button v-if="fastAvaliacaoAlunoAuditoria.length" @click="showModal('modalExibeAuditoriaAvaliacao')" class="btn btn-info m-1"> <small><b-icon-exclamation-circle-fill /> auditoria</small></button>
                                  <div class="mt-2">
                                    <uploader
                                      :file-status-text="statusText"
                                      :options="optionsArquivoGabaritoAvaliacao"
                                      class="uploader-example"
                                      @file-success="fileSuccessGabaritoAvaliacao"
                                      @file-added="fileValidation"
                                    >
                                      <uploader-unsupport />
                                      <uploader-drop>
                                        <p>Enviar gabarito assinado (somente pdf, jpg, jpeg, gif ou png)</p>
                                        <uploader-btn :attrs="restrictArquivo">
                                          Clique aqui para selecionar
                                        </uploader-btn>
                                      </uploader-drop>
                                      <uploader-list />
                                    </uploader>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div v-else class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <h6>Avaliação não configurada para esta UC</h6>
                          </div>
                          
                          <div v-if="fastAlunoUnidade.recuperacao_configurada && fastAlunoUnidade.id_secretaria_avaliacao_recuperacao" class="col-12 mt-4">
                            <div v-if="fastAlunoUnidade.id_curso_uc_recuperacao" class="row">                              
                              <div class="col-12 mt-2">
                                <h6>Aulas da unidade curricular de recuperação ({{fastUsuarioCursoRecuperacao.filtro.length}})
                                  <!-- Escondido, requer status de encerrado da uc de recuperação -->
                                  <button v-if="verificaAulasFinalizadas(fastUsuarioCursoRecuperacao.total, 'R')" @click="exibeModalFinalizarUnidade(fastAlunoUnidade, 'R')" class="btn btn-danger btn-sm ml-2 pt-0 pb-0 d-none">
                                    <small>Finalizar unidade curricular de recuperação</small>
                                  </button>
                                </h6>
                              </div>
                              <div class="col-12">
                                <div
                                  :class="'progress-bar progress-bar-striped progress-bar-animated bg-success'"
                                  role="progressbar"
                                  :aria-valuenow="{ width: fastUnidadePorcentagemRecuperacao }"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  :style="{ width: fastUnidadePorcentagemRecuperacao + '%' }"
                                >
                                  <small class="m-0">
                                    {{ parseFloat(fastUnidadePorcentagemRecuperacao).toFixed(2) + '%'}}
                                  </small>
                                </div>
                              </div>
                              <div v-if="fastUsuarioCursoRecuperacao.loading" class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center">
                                <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Carregando aulas
                              </div>
                              <div v-else-if="!fastUsuarioCursoRecuperacao.filtro.length" class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center">
                                  <p>Nenhuma aula identificada</p>
                              </div>
                              <div v-else class="col-12">
                                <div class="row">
                                  <div v-for="(aula, index) in fastUsuarioCursoRecuperacao.paginacao" :key="index" class="col-sm-12 col-md-12 col-lg-12 mt-2">
                                    <b-card header-tag="header" footer-tag="footer">
                                      <template #header>
                                        <h6 class="mb-0">Aula  {{aula.sequencia_aula}}</h6>
                                      </template>
                                      <b-card-text>
                                        <div>
                                          <h4> <a href="#" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + fastAlunoUnidade.id_curso_uc_recuperacao + '/aulas?aula=' + aula.id_aula)">{{aula.titulo_aula}}</a></h4>
                                        </div>
                                        <div class="mb-2">
                                          {{aula.detalhe_aula}}
                                        </div>
                                        <div v-if="aula.data_inicio">
                                          Data de início: {{formataDataHoraT(aula.data_inicio)}}
                                        </div>
                                        <div v-if="aula.data_fim">
                                          Data de finalização: {{formataDataHoraT(aula.data_fim)}}
                                        </div>
                                        <div v-if="aula.count">
                                          Realizou <b-badge variant="secondary">{{aula.count}}</b-badge> veze(s)
                                        </div>
                                        <div class="mt-2">
                                          <b-badge v-if="!aula.status" variant="secondary">Aula não iniciada</b-badge>
                                          <b-badge v-else-if="aula.status == 'F'" variant="success">Aula finalizada</b-badge>                                 
                                          <b-badge v-else-if="aula.status == 'E'" variant="warning">Aula pendente</b-badge>
                                          <b-badge v-else variant="danger">Status não identificado</b-badge>
                                        </div>    
                                        <div v-if="verificaAtividadesFinalizadas(aula.atividades.total)" class="mt-2">
                                          <button v-if="aula.status != 'F'" @click="exibeModalFinalizarAula(aula, 'R')" class="btn btn-danger btn-sm mt-2 pt-0 pb-0">
                                            <small>Finalizar aula</small>
                                          </button>  
                                        </div>  
                                        <div class="mt-2 text-center">
                                          <button v-if="aula.atividades.loading" @click="getUsuarioCursoAulaAtividades($route.params.id_plataforma, fastAluno.id_usuario, aula.id_aula, 'R')" class="btn btn-sm btn-primary"><b-icon-eye-fill /> Exibir atividades</button>
                                          <div v-else-if="!aula.atividades.total.length" class="col-12 text-center">
                                            <p>Nenhum atividade identificada</p>
                                          </div>
                                          <div v-else class="col-12 mt-2">
                                            <div class="row mt-4">
                                              <h6>Lista de atividades ({{aula.atividades.total.length}})</h6>
                                            </div>
                                            <div class="row mt-2 text-left">
                                              <table class="table table-striped table-bordered">
                                                <thead>
                                                  <tr class="bg-dark text-white">
                                                    <th
                                                      style="width: 50px"
                                                      scope="col"
                                                    >
                                                      Sequência
                                                    </th>
                                                    <th scope="col">
                                                      Título da atividade
                                                    </th>
                                                    <th
                                                      class="text-center"
                                                      scope="col"
                                                    >
                                                      Tipo
                                                    </th>
                                                    <th
                                                      class="text-center"
                                                      scope="col"
                                                    >
                                                      Data início
                                                    </th>
                                                    <th
                                                      class="text-center"
                                                      scope="col"
                                                    >
                                                      Data fim
                                                    </th>
                                                    <th
                                                      class="text-center"
                                                      scope="col"
                                                    >
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr v-for="(atividade, index2) in aula.atividades.total" :key="index2">
                                                    <td class="text-center">
                                                      {{ atividade.sequencia_aula }}
                                                    </td>
                                                    <td class="text-left">
                                                      {{ atividade.titulo_atividade }}
                                                    </td>
                                                    <td class="text-center">
                                                      {{ formataTipoAtividade(atividade.tipo_atividade) }}
                                                    </td>                                                    
                                                    <td class="text-center">
                                                      {{ formataDataHoraT(atividade.data_inicio) }}
                                                    </td>
                                                    <td class="text-center">
                                                      {{ formataDataHoraT(atividade.data_fim) }}
                                                    </td>
                                                    <td class="text-center">
                                                      <a
                                                        class="btn btn-primary btn-sm mt-2 ml-2"
                                                        :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + fastAlunoUnidade.id_curso_uc_recuperacao + '/aulas/' + aula.id_aula + '/atividade/' + atividade.id_atividade"
                                                        @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/unidade-curricular/' + fastAlunoUnidade.id_curso_uc_recuperacao + '/aulas/' + aula.id_aula + '/atividade/' + atividade.id_atividade)"
                                                      >
                                                        <small>
                                                          <i
                                                            class="fa fa-cog"
                                                            aria-hidden="true"
                                                          />
                                                          &nbsp; Acessar
                                                        </small>
                                                      </a>
                                                      <button v-if="atividade.atividade_replicada" class="btn btn-danger btn-sm mt-2 ml-2" @click="exibeModalExibirAtividadesReplicadas(atividade)">
                                                        <small>
                                                          <b-icon icon="exclamation-circle-fill"></b-icon> Replicada
                                                        </small>                                                        
                                                      </button>
                                                      <button v-else-if="!atividade.data_fim && atividade.data_inicio" @click="exibeModalFinalizarAtividade(atividade, 'R')" class="btn btn-danger btn-sm mt-2 ml-2">
                                                        <small>Finalizar</small>
                                                      </button>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>                                  
                                      </b-card-text>
                                    </b-card>
                                  </div>
                                  <div class="col-12 text-center mt-2">
                                    <Pagination
                                      :page-size="1"
                                      :items="fastUsuarioCursoRecuperacao.filtro"
                                      @changePage="fastUsuarioCursoRecuperacao.paginacao = $event"
                                    />
                                  </div>
                                </div>                                 
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 mt-2">
                                <h6>Avaliação de recuperação</h6>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
                                <b-list-group>

                                  <b-list-group-item class="d-flex align-items-center p-4">
                                    <b-icon-info-circle class="mr-4" scale="2" />
                                    <span class="mr-auto">Data da realização</span>
                                    <h5 v-if="fastAlunoUnidade.id_secretaria_recuperacao_aluno_avaliacao"><b-badge>{{formataDataT(fastAlunoUnidade.data_fim_recuperacao)}}</b-badge></h5>
                                    <h5 v-else><b-badge>Nunca</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastRecuperacaoConfiguracao.nota_maxima" class="d-flex align-items-center p-4">
                                    <b-icon-emoji-sunglasses class="mr-4" scale="2" />
                                    <span class="mr-auto">Nota máxima</span>
                                    <h5><b-badge>{{ fastRecuperacaoConfiguracao.nota_maxima }}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastRecuperacaoConfiguracao.media_recuperacao" class="d-flex align-items-center p-4">
                                    <b-icon-emoji-smile class="mr-4" scale="2" />
                                    <span class="mr-auto">Média para aprovação</span>
                                    <h5><b-badge>{{ fastRecuperacaoConfiguracao.media_recuperacao }}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item class="d-flex align-items-center p-4">
                                    <b-icon-graph-up class="mr-4" scale="2" />
                                    <span class="mr-auto">Peso da avaliação</span>
                                    <h5><b-badge>{{ fastRecuperacaoPeso ? fastRecuperacaoPeso : "Sem peso" }}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item class="d-flex align-items-center p-4">
                                    <b-icon-info-circle class="mr-4" scale="2" />
                                    <span class="mr-auto">Tentativas máximas</span>
                                    <h5 v-if="fastAlunoUnidade.id_secretaria_recuperacao_aluno_avaliacao"><b-badge>{{ fastAlunoUnidade.tentativas_maxima_recuperacao_aluno ? fastAlunoUnidade.tentativas_maxima_recuperacao_aluno : "ilimitada" }}</b-badge></h5>
                                    <h5 v-else><b-badge>{{ fastAlunoUnidade.tentativas_maxima_recuperacao ? fastAlunoUnidade.tentativas_maxima_recuperacao : "ilimitada" }}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastAlunoUnidade.id_secretaria_recuperacao_aluno_avaliacao" class="d-flex align-items-center p-4">
                                    <b-icon-info-circle class="mr-4" scale="2" />
                                    <span class="mr-auto">Tentativas realizadas</span>
                                    <h5><b-badge>{{ fastAlunoUnidade.count_recuperacao ? fastAlunoUnidade.count_recuperacao : "0" }}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastRecuperacaoPeso && fastAlunoUnidade.id_secretaria_recuperacao_aluno_avaliacao" class="d-flex align-items-center p-4">
                                    <b-icon-ui-checks class="mr-4" scale="2" />
                                    <span class="mr-auto">Nota atingida</span>
                                    <h5><b-badge>{{fastAlunoUnidade.nota_final_recuperacao}}</b-badge></h5>

                                    <h5 v-if="fastNotaRecuperacaoArredondada && fastNotaRecuperacaoArredondada > fastNotaRecuperacao"><b-badge variant="success" class="ml-1"><b-icon-arrow-up /> {{fastNotaRecuperacaoArredondada}}</b-badge></h5>
                                    <h5 v-else-if="fastNotaRecuperacaoArredondada && fastNotaRecuperacaoArredondada < fastNotaRecuperacao"><b-badge variant="danger" class="ml-1"><b-icon-arrow-down /> {{fastNotaRecuperacaoArredondada}}</b-badge></h5>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastAlunoUnidade.id_secretaria_recuperacao_aluno_avaliacao" class="d-flex align-items-center p-4">
                                    <b-icon-info-circle class="mr-4" scale="2" />
                                    <span class="mr-auto">Aprovado</span>
                                    <h5 v-if="fastAlunoUnidade.aprovado_recuperacao"><b-badge variant="success">Sim</b-badge></h5>
                                    <h5 v-else><b-badge variant="danger">Não</b-badge></h5>
                                  </b-list-group-item>

                                </b-list-group>
                              </div>

                              <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
                                <b-list-group>
                                  <b-list-group-item>
                                      <div
                                        v-if="chartRecuperacaoVisivel"
                                        class="d-lg-flex justify-content-center"
                                      >
                                        <div class="d-flex justify-content-center">
                                          <VueApexCharts
                                            width="380"
                                            :options="chartOptionsRecuperacaoPeso"
                                            :series="chartDataRecuperacaoPeso"
                                          />
                                        </div>   
                                      </div>
                                      <div
                                        v-if="chartRecuperacaoVisivel"
                                        class="d-lg-flex justify-content-center"
                                      >                                  
                                        <div class="d-flex justify-content-center">
                                          <VueApexCharts
                                            width="380"
                                            :options="chartOptionsRecuperacao"
                                            :series="chartDataRecuperacao"
                                          />
                                        </div>
                                      </div>
                                      <div v-else class="col-12 text-center">
                                        Sem dados estatísticos
                                      </div>
                                      
                                  </b-list-group-item>
                                </b-list-group>

                                <div v-if="fastAlunoUnidade.id_secretaria_recuperacao_aluno_avaliacao" class="mt-4">
                                  <button @click="showModal('modalEditarRecuperacao')" class="btn btn-primary m-1"> <small><b-icon-gear-fill /> alterar dados</small></button>
                                    <span
                                    class="btn btn-primary m-1"
                                    @click="showModal('modalRespostasRecuperacao')"
                                  >
                                    <small><b-icon-eye-fill /> Ver respostas</small>
                                  </span>
                                  <a      
                                    v-if="fastGabaritoRecuperacao"                            
                                    :href="ajustaLinkApiAntiga(fastGabaritoRecuperacao)"
                                    target="_blank"
                                    class="btn btn-success m-1"
                                  >
                                    <small><b-icon-download /> Gabarito assinado</small>
                                  </a>
                                  <span
                                    v-else
                                    class="btn btn-secondary disabled m-1"
                                  >
                                    <small>Gabarito assinado</small>
                                  </span>
                                  <button v-if="fastAvaliacaoAlunoAuditoriaRecuperacao.length" @click="showModal('modalExibeAuditoriaRecuperacao')" class="btn btn-info m-1"> <small><b-icon-exclamation-circle-fill /> auditoria</small></button>
                                  <div class="mt-2">
                                    <uploader
                                      :file-status-text="statusText"
                                      :options="optionsArquivoGabaritoRecuperacao"
                                      class="uploader-example"
                                      @file-success="fileSuccessGabaritoRecuperacao"
                                      @file-added="fileValidation"
                                    >
                                      <uploader-unsupport />
                                      <uploader-drop>
                                        <p>Enviar gabarito assinado (somente pdf, jpg, jpeg, gif ou png)</p>
                                        <uploader-btn :attrs="restrictArquivo">
                                          Clique aqui para selecionar
                                        </uploader-btn>
                                      </uploader-drop>
                                      <uploader-list />
                                    </uploader>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <h6>Recuperação não configurada para esta UC</h6>
                          </div>

                          <div class="col-12 mt-4">
                            <a
                              class="btn btn-secondary"
                              href="#"
                              @click.prevent="fastNavegacaoEstatisticas.exibirUnidade = false; fastNavegacaoEstatisticas.exibirModulo = true"  
                            >
                              <b-icon-arrow-return-left />
                              Voltar
                            </a>
                          </div>                          
                        </div>
                        </transition>

                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">   
                  <div v-if="fastNavegacao.exibirBoletim" class="col-12 mt-2">
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Boletim do aluno 
                              <span v-if="fastAluno.nome_razao">
                                {{fastAluno.nome_razao + " " + fastAluno.sobrenome_fantasia}}
                              </span>
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right text-xs font-weight-bold text-info">
                            <span>Matriculado</span> 
                            <span v-if="!fastAluno.matricula_por_programa && !fastAluno.matricula_por_unidade">
                              no curso inteiro
                            </span>
                            <span v-else-if="fastAluno.matricula_por_programa">
                              em módulo(s)
                            </span>
                            <span v-if="fastAluno.matricula_por_unidade">
                              em unidade(s)
                            </span> 
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row no-gutters">
                          <div class="col">
                            <div class="h5 font-weight-bold text-secondary">                                              
                              <span v-if="fastAluno.nome_razao">
                                <a :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + fastAluno.id_pessoa_aluno" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + fastAluno.id_pessoa_aluno)">
                                  {{fastAluno.nome_razao + " " + fastAluno.sobrenome_fantasia}}
                                </a>  
                              </span>     
                              <span v-else>Nome não registrado</span>  
                            </div>
                            <div class="text-xs font-weight-bold text-secondary mb-1">
                              <span>CPF {{fastAluno.cpf_cnpj}}</span>  
                            </div>
                            <div class="text-xs font-weight-bold text-secondary mb-1">
                              <span>Matrícula #{{fastAluno.id_matricula}}</span>  
                            </div>   
                                             
                          </div>
                          <div class="col text-right">
                            
                          </div>
                        </div>

                        <transition name="slide-fade"> 
                        <div v-if="fastNavegacaoEstatisticas.exibirModulos" class="row mt-4">
                          <div class="col-12">
                            <div class="row mb-4">
                              <div class="col-12 text-center text-secondary">
                                <h4>Módulos do curso ({{fastAlunoModulos.total.length}})</h4>
                              </div>
                            </div>
                            <div v-if="fastAlunoModulos.loading" class="row">
                              <div class="col-12 text-center">
                                <b-icon icon="gear-fill" animation="spin" /> Carregando módulos
                              </div>
                            </div>
                            <div v-else-if="!fastAlunoModulos.total.length" class="row">
                              <div class="col-12 text-center">
                                Nenhum módulo encontrado
                              </div>
                            </div>
                            <div v-else class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                                <table class="table table-sm">
                                  <thead class="thead-dark">
                                    <tr>
                                      <th><small class="font-weight-bold"></small></th>
                                      <th><small class="font-weight-bold">Módulo</small></th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Unidades finalizadas</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Unidades totais</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold"></small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(m, index) in fastAlunoModulos.total" :key="index">
                                      <td>
                                        {{index + 1}}
                                      </td>
                                      <td>
                                        {{m.sigla_programa}}
                                      </td>
                                      <td class="text-center">
                                        {{m.cursos_finalizados}}
                                      </td>
                                      <td class="text-center">
                                        {{m.total_cursos}}
                                      </td>
                                      <td>
                                        <div>
                                          <input type="checkbox" class="form-control form-control-sm" style="width: 20px" @click="checkModuloBoletim(m)" checked>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="5" class="text-right">
                                        <button class="btn btn-primary pt-0 pb-0" @click="exibeModalBoletim()">
                                          <small>Gerar boletim</small>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              
                            </div>
                          </div>
                          
                        </div>
                        </transition>

                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  v-if="fastNavegacao.exibirEstatisticas"
                  class="btn btn-red-hollow text-danger"
                  @click.prevent="fastNavegacaoEstatisticas.exibirUnidade = false; fastNavegacaoEstatisticas.exibirModulo = false; fastNavegacaoEstatisticas.exibirModulos = true; alteraFastNavegacao('exibirAlunos')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para alunos
                </a>
                <a
                  v-else
                  class="btn btn-red-hollow"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/turmas')"  
                >
                  <b-icon-arrow-return-left />
                  Voltar para turmas
                </a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalRespostasAvaliacao"
      :width="modalWidthLg"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Respostas do aluno</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalRespostasAvaliacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row p-lg-4">
            <div class="col-12 pl-lg-4 pr-lg-4">
              <div class="row pl-2 pr-2 pb-lg-4">
                <carousel
                  :navigation-enabled="true"
                  :per-page="1"
                  :touch-drag="true"
                  :mouse-drag="true"
                  :adjustable-height="true"
                  :pagination-size="20"
                  :min-swipe-distance="100"
                  class="col-12 p-2 mb-2 bg-white rounded"
                >
                  <slide
                    v-for="(q, index) in fastRespostasAvaliacao"
                    :key="q.id_questao"
                  >
                    <div class="col-12 mt-2">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <h5>
                            Questão #{{q.id_questao}} - {{ index + 1 }} de
                            {{ fastRespostasAvaliacao.length }}
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <h5 class="text-danger">
                            Peso: {{q.questao_peso}}
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <h5 class="text-danger">
                            Data: {{formataDataHoraT(q.data_hora)}}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-2 max-width-100vw">
                      <div v-html="q.pergunta" />
                    </div>
                    <div
                      v-if="q.alternativas.length"
                      class="col-12 p-2 max-width-100vw"
                    >
                      <div
                        v-for="(a) in q.alternativas"
                        :key="a.id_alternativa"
                        class="row pl-2 pr-2"
                      >
                        <div class="col-12">
                          <div
                            v-if="a.opcao_escolhida == a.id_alternativa && q.acertou == 'S'"
                            :class="'d-flex fast-aula-alternativa-escolhida-correta'"
                          >
                            <div class="pr-2">
                              <h6>Alternativa #{{a.id_alternativa}} -</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else-if="a.opcao_escolhida == a.id_alternativa && q.acertou == 'N'"
                            :class="'d-flex fast-aula-alternativa-escolhida-incorreta'"
                          >
                            <div class="pr-2">
                              <h6>Alternativa #{{a.id_alternativa}} -</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else
                            :class="'d-flex fast-aula-alternativa'"
                          >
                            <div class="pr-2">
                              <h6>Alternativa #{{a.id_alternativa}} -</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalRespostasRecuperacao"
      :width="modalWidthLg"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Respostas do aluno</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalRespostasRecuperacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row p-lg-4">
            <div class="col-12 pl-lg-4 pr-lg-4">
              <div class="row pl-2 pr-2 pb-lg-4">
                <carousel
                  :navigation-enabled="true"
                  :per-page="1"
                  :touch-drag="true"
                  :mouse-drag="true"
                  :adjustable-height="true"
                  :pagination-size="20"
                  :min-swipe-distance="100"
                  class="col-12 p-2 mb-2 bg-white rounded"
                >
                  <slide
                    v-for="(q, index) in fastRespostasRecuperacao"
                    :key="q.id_questao"
                  >
                    <div class="col-12 mt-2">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <h5>
                            Questão #{{q.id_questao}} - {{ index + 1 }} de
                            {{ fastRespostasRecuperacao.length }}
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <h5 class="text-danger">
                            Peso: {{q.questao_peso}}
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <h5 class="text-danger">
                            Data: {{formataDataHoraT(q.data_hora)}}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-2 max-width-100vw">
                      <div v-html="q.pergunta" />
                    </div>
                    <div
                      v-if="q.alternativas.length"
                      class="col-12 p-2 max-width-100vw"
                    >
                      <div
                        v-for="(a) in q.alternativas"
                        :key="a.id_alternativa"
                        class="row pl-2 pr-2"
                      >
                        <div class="col-12">
                          <div
                            v-if="a.opcao_escolhida == a.id_alternativa && q.acertou == 'S'"
                            :class="'d-flex fast-aula-alternativa-escolhida-correta'"
                          >
                            <div class="pr-2">
                              <h6>Alternativa #{{a.id_alternativa}} -</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else-if="a.opcao_escolhida == a.id_alternativa && q.acertou == 'N'"
                            :class="'d-flex fast-aula-alternativa-escolhida-incorreta'"
                          >
                            <div class="pr-2">
                              <h6>Alternativa #{{a.id_alternativa}} -</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else
                            :class="'d-flex fast-aula-alternativa'"
                          >
                            <div class="pr-2">
                              <h6>Alternativa #{{a.id_alternativa}} -</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarAvaliacao"
      :width="modalWidthSm"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Editar avaliação do aluno</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarAvaliacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Tempo de atividade</label>
                <input type="time" class="form-control" v-model="fastSecretariaAvaliacaoAlunoEditar.tempo_atividade">
              </div>
              <div class="form-group">
                <label>Tentativas máxima</label>
                <input type="number" class="form-control" v-model="fastSecretariaAvaliacaoAlunoEditar.tentativas_maxima">
              </div>
              <div class="form-group">
                <label>Nota final</label>
                <input type="number" step=".01" class="form-control" v-model="fastSecretariaAvaliacaoAlunoEditar.nota_final">
              </div>
              <div class="form-group">
                <label>Aprovado</label>
                <select class="form-control" v-model="fastSecretariaAvaliacaoAlunoEditar.aprovado">
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
              </div>
              <div class="form-group">
                <label>Justificativa da alteração</label>
                <textarea class="form-control" v-model="fastAvaliacaoAlunoAuditoriaJustificativa"></textarea>
              </div>
              <div class="mt-4">
                <button class="btn btn-sm btn-primary" @click="atualizaAvaliacaoAluno('A')">
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarRecuperacao"
      :width="modalWidthSm"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Editar recuperação do aluno</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarRecuperacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Tempo de atividade</label>
                <input type="time" class="form-control" v-model="fastSecretariaRecuperacaoAlunoEditar.tempo_atividade">
              </div>
              <div class="form-group">
                <label>Tentativas máxima</label>
                <input type="number" class="form-control" v-model="fastSecretariaRecuperacaoAlunoEditar.tentativas_maxima">
              </div>
              <div class="form-group">
                <label>Nota final</label>
                <input type="number" step=".01" class="form-control" v-model="fastSecretariaRecuperacaoAlunoEditar.nota_final">
              </div>
              <div class="form-group">
                <label>Aprovado</label>
                <select class="form-control" v-model="fastSecretariaRecuperacaoAlunoEditar.aprovado">
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
              </div>
              <div class="form-group">
                <label>Justificativa da alteração</label>
                <textarea class="form-control" v-model="fastAvaliacaoAlunoAuditoriaJustificativa"></textarea>
              </div>
              <div class="mt-4">
                <button class="btn btn-sm btn-primary" @click="atualizaAvaliacaoAluno('R')">
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalFinalizarAtividade"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Forçar finalização?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalFinalizarAtividade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h6>A atividade será finalizada manualmente.</h6>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="finalizarAtividade()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalFinalizarAula"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Forçar finalização?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalFinalizarAula')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h6>A aula será finalizada manualmente.</h6>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="finalizarAula()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalFinalizarUnidade"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Forçar finalização?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalFinalizarUnidade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h6>A unidade curricular será finalizada manualmente.</h6>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="finalizarUnidade()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExibeAuditoriaAvaliacao"
      :width="modalWidthLg"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Auditoria da avaliação</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExibeAuditoriaAvaliacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row">
            <div class="col-12 table-responsive">
              <table class="table table-sm table-striped">
                <thead class="thead-dark">
                  <tr>                    
                    <th>
                      Pessoa
                    </th>
                    <th>
                     Email
                    </th>
                    <th>
                      Data da alteração
                    </th>
                    <th>
                      Ações
                    </th>
                    <th>
                      Justificativa
                    </th>
                    <th>
                      Dispositivo
                    </th>
                    <th>
                      Navegador
                    </th>
                    <th>
                      Endereço IP
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!fastAvaliacaoAlunoAuditoria.length">
                  <tr>
                    <td colspan="2" class="text-center">Nenhuma informação encontrada</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(a, index) in fastAvaliacaoAlunoAuditoria" :key="index">
                    <td>
                      #{{a.id_pessoa}} - {{a.nome_razao}} {{a.sobrenome_fantasia}}
                    </td>
                    <td>
                      {{a.email}}
                    </td>
                    <td>
                      {{formataDataHoraT(a.data_alteracao)}}
                    </td>
                    <td>
                      <div v-if="a.tempo_atividade_anterior != a.tempo_atividade_alterado">
                        <span>Alterou o tempo da avaliação de <b class="text-danger">{{a.tempo_atividade_anterior}}</b> para <b class="text-danger">{{a.tempo_atividade_alterado}}</b> </span>
                      </div>
                      <div v-if="a.tentativas_maxima_anterior != a.tentativas_maxima_alterado">
                        <span>Alterou o número de tentativas de <b class="text-danger">{{a.tentativas_maxima_anterior}}</b> para <b class="text-danger">{{a.tentativas_maxima_alterado}}</b> </span>
                      </div>
                      <div v-if="a.nota_final_anterior != a.nota_final_alterado">
                        <span>Alterou a nota final da avaliação de <b class="text-danger">{{a.nota_final_anterior}}</b> para <b class="text-danger">{{a.nota_final_alterado}}</b> </span>
                      </div>
                      <div v-if="a.aprovado_anterior != a.aprovado_alterado">
                        <span>
                          Alterou o status de aprovação de
                          <b v-if="a.aprovado_anterior" class="text-success">Aprovado</b> 
                          <b v-else class="text-danger">Reprovado</b> 
                          para 
                          <b v-if="a.aprovado_alterado" class="text-success">Aprovado</b> 
                          <b v-else class="text-danger">Reprovado</b> 
                        </span>
                      </div>
                    </td>
                    <td>
                      {{a.justificativa}}
                    </td>
                    <td>
                      <span v-if="a.tipo_dispositivo == 'D'">
                        Desktop
                      </span>
                      <span v-else-if="a.tipo_dispositivo == 'M'">
                        Mobile
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não identificado </span>
                    </td>
                    <td>
                      <span v-if="a.user_agent">
                        {{ detectaBrowser(a.user_agent) }}
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não registrado </span>
                    </td>
                    <td>
                      <span v-if="a.endereco_ip">
                        <a :href="'https://whatismyipaddress.com/ip/' + a.endereco_ip.trim()" target="_blank">{{ a.endereco_ip }}</a>                        
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não registrado </span>
                    </td>
                  </tr>
                </tbody>
            </table>
            </div>            
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExibeAuditoriaRecuperacao"
      :width="modalWidthLg"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Auditoria da recuperação</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExibeAuditoriaRecuperacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">          
          <div class="row">
            <div class="col-12 table-responsive">
              <table class="table table-sm table-striped">
                <thead class="thead-dark">
                  <tr>                    
                    <th>
                      Pessoa
                    </th>
                    <th>
                     Email
                    </th>
                    <th>
                      Data da alteração
                    </th>
                    <th>
                      Ações
                    </th>
                    <th>
                      Justificativa
                    </th>
                    <th>
                      Dispositivo
                    </th>
                    <th>
                      Navegador
                    </th>
                    <th>
                      Endereço IP
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!fastAvaliacaoAlunoAuditoriaRecuperacao.length">
                  <tr>
                    <td colspan="2" class="text-center">Nenhuma informação encontrada</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(a, index) in fastAvaliacaoAlunoAuditoriaRecuperacao" :key="index">
                    <td>
                      #{{a.id_pessoa}} - {{a.nome_razao}} {{a.sobrenome_fantasia}}
                    </td>
                    <td>
                      {{a.email}}
                    </td>
                    <td>
                      {{formataDataHoraT(a.data_alteracao)}}
                    </td>
                    <td>
                      <div v-if="a.tempo_atividade_anterior != a.tempo_atividade_alterado">
                        <span>Alterou o tempo da avaliação de <b class="text-danger">{{a.tempo_atividade_anterior}}</b> para <b class="text-danger">{{a.tempo_atividade_alterado}}</b> </span>
                      </div>
                      <div v-if="a.tentativas_maxima_anterior != a.tentativas_maxima_alterado">
                        <span>Alterou o número de tentativas de <b class="text-danger">{{a.tentativas_maxima_anterior}}</b> para <b class="text-danger">{{a.tentativas_maxima_alterado}}</b> </span>
                      </div>
                      <div v-if="a.nota_final_anterior != a.nota_final_alterado">
                        <span>Alterou a nota final da avaliação de <b class="text-danger">{{a.nota_final_anterior}}</b> para <b class="text-danger">{{a.nota_final_alterado}}</b> </span>
                      </div>
                      <div v-if="a.aprovado_anterior != a.aprovado_alterado">
                        <span>
                          Alterou o status de aprovação de
                          <b v-if="a.aprovado_anterior" class="text-success">Aprovado</b> 
                          <b v-else class="text-danger">Reprovado</b> 
                          para 
                          <b v-if="a.aprovado_alterado" class="text-success">Aprovado</b> 
                          <b v-else class="text-danger">Reprovado</b> 
                        </span>
                      </div>
                    </td>
                    <td>
                      {{a.justificativa}}
                    </td>
                    <td>
                      <span v-if="a.tipo_dispositivo == 'D'">
                        Desktop
                      </span>
                      <span v-else-if="a.tipo_dispositivo == 'M'">
                        Mobile
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não identificado </span>
                    </td>
                    <td>
                      <span v-if="a.user_agent">
                        {{ detectaBrowser(a.user_agent) }}
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não registrado </span>
                    </td>
                    <td>
                      <span v-if="a.endereco_ip">
                        <a :href="'https://whatismyipaddress.com/ip/' + a.endereco_ip.trim()" target="_blank">{{ a.endereco_ip }}</a>                        
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não registrado </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>            
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExibirCalendarioHistoricoTurma"
      :width="modalWidthLg"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      
    </modal>

    

    <b-modal id="modalExibirCalendarioHistoricoTurma" size="xl" scrollable title="Histórico de eventos" >
      <div v-if="fasCalendarioNavegacao.exibirCalendario">
        <fast-calendar :anoBase="anoBaseCalendario" :exibirFeriados="false" :exibirDescricaoDatas="false" :retornaDataSelecionada="diaCalendarioClicadoTurma" :eventosAluno="fastHistoricoDias.total" />
      </div>
      <div v-else-if="fasCalendarioNavegacao.exibirDiaSelecionado">
        <h1 class="text-center text-info">
          {{fastHistoricoDiaSelecionado.data_selecionada}} 
          <a
            class="fast-cursor-refresh"
            @click.prevent="diaCalendarioClicadoTurma({ date: fastHistoricoDiaSelecionado.data_selecionada})"
          >
            <b-icon-arrow-counterclockwise font-scale="1" />
          </a>
        </h1>
        <div class="row">
          <div class="col-12 fast-container-timeline-body">

            <div v-if="fastHistoricoDiaSelecionado.loading" class="text-center">
              <b-icon icon="gear-fill" animation="spin" /> Carregando
            </div>
            <div v-else-if="fastHistoricoDiaSelecionado.total.length" class="fast-container-timeline">
              <div v-for="(e, index) in fastHistoricoDiaSelecionado.total" :key="index" :class="index % 2 == 0 ? 'fast-container-timeline-item fast-container-timeline-item-left' : 'fast-container-timeline-item fast-container-timeline-item-right'">
                <div class="fast-container-timeline-item-content text-secondary">
                  <b-card-group >
                    <b-card :header="formataDataHoraT(e.data_evento)">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 text-info">
                          <b><b-icon-info-circle /> {{e.descricao_evento}}</b>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div v-if="e.nome_plataforma" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Plataforma - <b>{{e.nome_plataforma}}</b></small>
                        </div>
                        <div v-if="e.sigla_turma" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Turma - <b>{{e.sigla_turma}}</b></small>
                        </div>
                        <div v-if="e.nome_curso_secretaria" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Curso - <b>{{e.nome_curso_secretaria}}</b></small>
                        </div>
                        <div v-if="e.sigla_programa" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Módulo {{e.sequencia_modulo}} - <b>{{e.sigla_programa}}</b></small>
                        </div>
                        <div v-if="e.nome_curso && e.nome_curso_secretaria" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Unidade curricular - <b>{{e.nome_curso}}</b></small>
                        </div>
                        <div v-else-if="e.nome_curso && !e.nome_curso_secretaria" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Curso livre - <b>{{e.nome_curso}}</b></small>
                        </div>
                        <div v-if="e.titulo_aula" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Aula {{e.sequencia_aula}} - <b>{{e.titulo_aula}}</b></small>
                        </div>
                        <div v-if="e.tipo_avaliacao" class="col-sm-12 col-md-12 col-lg-12">
                          <small v-if="e.tipo_avaliacao == 'A'">Avaliação principal - <b>{{e.titulo_avaliacao}}</b></small>
                          <small v-else-if="e.tipo_avaliacao == 'R'">Recuperação - <b>{{e.titulo_avaliacao}}</b></small>
                        </div>                  
                      </div>
                      
                      <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <button v-b-toggle="'collapse-useragent-' + e.id_secretaria_aluno_auditoria" class="btn btn-sm btn-primary pt-0 pb-0"><small>Detalhes técnicos</small></button>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                          <b-collapse :id="'collapse-useragent-' + e.id_secretaria_aluno_auditoria">
                            <b-card>
                              <div class="row">
                                <div v-if="e.tipo_dispositivo == 'D'" class="col-sm-12 col-md-12 col-lg-12">
                                  <small>Dispositivo: <b>Desktop</b></small>
                                </div>
                                <div v-else-if="e.tipo_dispositivo == 'M'" class="col-sm-12 col-md-12 col-lg-12">
                                  <small>Dispositivo: <b>Mobile</b></small>
                                </div>
                                <div v-if="e.user_agent" class="col-sm-12 col-md-12 col-lg-12">
                                  <small>Browser: <b>{{ detectaBrowser(e.user_agent) }}</b> </small> 
                                </div>
                                <div v-if="e.user_agent" class="col-sm-12 col-md-12 col-lg-12">
                                  <small>User-agent: <br/><b>{{e.user_agent}}</b></small>                        
                                </div>
                                <div v-if="e.endereco_ip" class="col-sm-12 col-md-12 col-lg-12">
                                  <small>Endereço IP: <a :href="'https://whatismyipaddress.com/ip/' + e.endereco_ip.trim()" target="_blank">{{ e.endereco_ip }}</a></small>                        
                                </div>
                              </div>
                            </b-card>
                          </b-collapse>
                        </div>   
                      </div>
                    </b-card>
                  </b-card-group>
                </div>
              </div>
            </div>
            <div v-else class="text-center">
              Nenhum registro identificado
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button v-if="!fasCalendarioNavegacao.exibirCalendario" size="sm" variant="primary" @click="fasCalendarioNavegacao.exibirCalendario = true; fasCalendarioNavegacao.exibirDiaSelecionado = false;">
            <b-icon-arrow-return-left /> Calendário
        </b-button>
        <b-button size="sm" variant="secondary" @click="hide('forget')">
            Fechar <b-icon-x-circle-fill />
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modalExibirAulasReplicadas" size="xl" scrollable title="Aula replicada detectada" >
      <div class="col-12 p-4">
        <div v-for="(aula, index) in fastUsuarioCursoAulasReplicadas" :key="index" class="row mb-4">
          <div :class="aula.id_usuario_curso_aula == fastUsuarioCursoAulaExcluir.id_usuario_curso_aula ? 'col-12 border p-4 bg-light' : 'col-12 border p-4 bg-white'">
            <div class="row">
              <div class="col-12">
                <h6>
                  Aula {{aula.sequencia_aula}} - {{aula.titulo_aula}}
                </h6>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">              
                <div v-if="aula.data_inicio">
                  Data de início: {{formataDataHoraT(aula.data_inicio)}}
                </div>
                <div v-if="aula.data_fim">
                  Data de finalização: {{formataDataHoraT(aula.data_fim)}}
                </div>
                <div v-if="aula.count">
                  Realizou <b-badge variant="secondary">{{aula.count}}</b-badge> veze(s)
                </div>
                <div class="mt-2">
                  <b-badge v-if="!aula.status" variant="secondary">Aula não iniciada</b-badge>
                  <b-badge v-else-if="aula.status == 'F'" variant="success">Aula finalizada</b-badge>                                 
                  <b-badge v-else-if="aula.status == 'E'" variant="warning">Aula pendente</b-badge>
                  <b-badge v-else variant="danger">Status não identificado</b-badge>
                </div> 
              </div>     
              <div class="col-sm-12 col-md-12 col-lg-6 d-flex justify-content-center align-items-center">
                <button class="btn btn-sm btn-danger" @click="exibeModalExcluiAulaReplicada(aula)">Excluir aula</button>
              </div>  
            </div>
          </div>                 
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button size="sm" variant="secondary" @click="hide('forget')">
            Fechar <b-icon-x-circle-fill />
        </b-button>
      </template>
    </b-modal>
    
    <b-modal id="modalExcluirAulaReplicada" scrollable title="Exclusão de aula" >
      <div class="col-12">
        <h6 class="text-danger">Tem certeza que deseja excluir?</h6>
      </div>
      <template #modal-footer="{ hide }">
        <b-button size="sm" variant="danger" @click="excluirAulaReplicada()">
            Confirmar <b-icon-x-circle-fill />
        </b-button>
        <b-button size="sm" variant="secondary" @click="hide('forget')">
            Cancelar <b-icon-x-circle-fill />
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modalExibirAtividadesReplicadas" size="xl" scrollable title="Atividade replicada detectada" >
      <div class="col-12 p-4">
        <div v-for="(atividade, index) in fastUsuarioCursoAtividadesReplicadas" :key="index" class="row mb-4">
          <div :class="atividade.id_usuario_atividade == fastUsuarioCursoAtividadeExcluir.id_usuario_atividade ? 'col-12 border p-4 bg-light' : 'col-12 border p-4 bg-white'">
            <div class="row">
              <div class="col-12">
                <h6>
                  Atividade {{atividade.sequencia_usuario_atividade}} - {{atividade.titulo_atividade}}
                </h6>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">              
                <div v-if="atividade.data_inicio">
                  Data de início: {{formataDataHoraT(atividade.data_inicio)}}
                </div>
                <div v-if="atividade.data_fim">
                  Data de finalização: {{formataDataHoraT(atividade.data_fim)}}
                </div>
                <div v-if="atividade.count">
                  Realizou <b-badge variant="secondary">{{atividade.count}}</b-badge> veze(s)
                </div>
                <div class="mt-2">
                  <b-badge v-if="!atividade.data_inicio" variant="secondary">Atividade não iniciada</b-badge>                                                 
                  <b-badge v-else-if="!atividade.data_fim" variant="warning">Atividade pendente</b-badge>
                  <b-badge v-else-if="atividade.data_fim" variant="success">Atividade finalizada</b-badge>  
                  <b-badge v-else variant="danger">Status não identificado</b-badge>
                </div> 
              </div>     
              <div class="col-sm-12 col-md-12 col-lg-6 d-flex justify-content-center align-items-center">
                <button class="btn btn-sm btn-danger" @click="exibeModalExcluiAtividadeReplicada(atividade)">Excluir atividade</button>
              </div>  
            </div>
          </div>                 
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button size="sm" variant="secondary" @click="hide('forget')">
            Fechar <b-icon-x-circle-fill />
        </b-button>
      </template>
    </b-modal>
    
    <b-modal id="modalExcluirAtividadeReplicada" scrollable title="Exclusão de atividade" >
      <div class="col-12">
        <h6 class="text-danger">Tem certeza que deseja excluir?</h6>
      </div>
      <template #modal-footer="{ hide }">
        <b-button size="sm" variant="danger" @click="excluirAtividadeReplicada()">
            Confirmar <b-icon-x-circle-fill />
        </b-button>
        <b-button size="sm" variant="secondary" @click="hide('forget')">
            Cancelar <b-icon-x-circle-fill />
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modalExibirBoletimTurma" size="xl" scrollable title="Boletim escolar" >
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-9">
          <div class="row">
            <div class="col-12">
              <div class="text-center p-4 border border-2 border-secondary">
                <h3>Extrato de Notas e Faltas Parcial</h3>
              </div>              
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-3">
          <img :src="imageSitePlataformaImg" class="mw-100">
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <span>Nome: <b>%nome_aluno%</b></span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <span>CPF: <b>%cpf_aluno%</b></span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <span>Nacionalidade: <b>%nacionalidade_aluno%</b></span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <span>Naturalidade: <b>%naturalidade_aluno%</b></span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <span>Nascimento: <b>%nascimento_aluno%</b></span>
            </div>
          </div>
          <hr class="mt-2 mb-2">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <span>Curso: <b>%nome_curso%, %nivel_curso%</b></span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <span>Renovado o Reconhecimento pela Portaria nº 913 de 27/12/2018 - publicada no D.O.U de 28/12/2018, seção I, pág. 134</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 table-responsive mt-4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ANO</th>
                    <th scope="col">DISCIPLINA</th>
                    <th scope="col">FALTAS POSSÍVEIS</th>
                    <th scope="col">FALTAS</th>
                    <th scope="col">CH</th>
                    <th scope="col">NOTA</th>
                    <th scope="col">SITUAÇÃO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="7">
                      <b>1º Semestre</b>
                    </td>
                  </tr>
                  <tr>
                    <td>2010</td>
                    <td>Altoritmos e Programação</td>
                    <td>-</td>
                    <td>-</td>
                    <td>90</td>
                    <td>9.00</td>
                    <td>Dispensado</td>
                  </tr>
                </tbody>
              </table>     
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <VueHtml2pdf
      ref="html2Pdf"
      class="mx-auto"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="955px"
      :manual-pagination="false"
      :paginate-elements-by-height="10000"
    >
      <section slot="pdf-content">
        <div
          class="fast-pdf-html-template"
          v-html="boletim_html_template"
        >
        </div>
      </section>
    </VueHtml2pdf>

  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import imageSitePlataformaImg from "@/assets/images/app/plataforma-img.jpg";
import VueApexCharts from "vue-apexcharts"; //https://github.com/apexcharts/vue-apexcharts https://apexcharts.com/vue-chart-demos
import { Carousel, Slide } from "vue-carousel"; //https://github.com/ssense/vue-carousel#readme
import FastCalendar from "../components/FastSecretariaCalendario";
import VueHtml2pdf from "vue-html2pdf"; //https://www.npmjs.com/package/vue-html2pdf

export default {
  name: "HomeInternoSecretariaTurmaAlunos",
  components: {
    Pagination, VueApexCharts, Carousel, Slide, FastCalendar, VueHtml2pdf
  },

  mixins: [methods],
  data: function () {
    return {
      imageSitePlataformaImg,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastNavegacao: {
        exibirAlunos: true,
        exibirEstatisticas: false,
        exibirBoletim: false
      },
      fastNavegacaoEstatisticas: {
        exibirModulos: true,
        exibirModulo: false,
        exibirUnidade: false
      },
      fastTurma: {
        ativo: "",
        data_modifica: "",
        data_registro: "",
        descricao_turma: "",
        etapa: "",
        id_plataforma: 0,
        id_turma: "",
        id_usuario: "",
        id_usuario_modifica: "",
        nome_plataforma: "",
        sigla_turma: "...",
        turno: "",
        id_secretaria_curso: 0,
        nome_curso: ""
      },
      // Alunos
      fastUsuariosAlunosLoading: true,
      fastUsuariosAlunos: [],
      fastUsuariosAlunosFiltro: [],
      pageOfAlunos: [],
      fastUsuarioAlunoSelecionado: {
        nome_usuario: ""
      },
      fastAluno: {
        RA: null,
        ajuste_definido: true,
        ano_matricula: "",
        ativo: true,
        ativo_na_plataforma: "",
        boleto_gerado: false,
        contrato_gerado: true,
        cpf_cnpj: "",
        curso_definido: true,
        data_matricula: "",
        data_pagto: null,
        desconto_matricula: 0,
        id_invoice: 0,
        id_matricula: 0,
        id_pessoa_aluno: 0,
        id_plataforma: 0,
        id_turma: 0,
        id_usuario: 0,
        id_usuario_baixa_manual: null,
        id_usuario_cadastro: 0,
        log_pagto_auditoria: null,
        matricula_por_programa: false,
        matricula_por_unidade: false,
        nome_razao: "",
        outros_valores: 0,
        pago: false,
        path_contrato: null,
        plano_pagto_definido: true,
        responsavel_definido: true,
        sobrenome_fantasia: "",
        tipo_matricula: "T",
        url_boleto: null,
        valor_matricula: 0,
        vencimento_matricula: null
      },
      fastAlunoModulos: {
        loading: true,
        total: []
      },
      fastAlunoModulo: {
        ano: "",
        ativo: "",
        creditos: 0,
        cursos_finalizados: 0,
        data_final: "",
        data_inicio: "",
        data_inicio_aluno: "",
        data_modifica: null,
        data_registro: "",
        descricao_turma: "",
        etapa: "",
        gera_certificado: false,
        id_pai: 0,
        id_plataforma: 0,
        id_programa_turma: 0,
        id_secretaria_curso: 0,
        id_secretaria_curso_programa: 0,
        id_turma: 0,
        id_usuario: 0,
        id_usuario_modifica: 0,
        nome_plataforma: "",
        preco: 0,
        sequencia: 0,
        sigla_programa: "",
        sigla_turma: "",
        total_cursos: 0,
        turno: 0
      },
      fastAlunoUnidades: {
        loading: true,
        total: []
      },
      fastAlunoUnidade: {
        acesso: "P",
        avaliacao_configurada: true,
        carga_horaria: 30,
        carga_horaria_mae: 0,
        count_avaliacao: 1,
        count_recuperacao: 0,
        count_curso: 1,
        creditos: 10,
        data_disponivel: "",
        data_expiracao: "",
        data_fim_avaliacao: "",
        data_fim_recuperacao: null,
        data_fim_uc: "",
        data_final: "",
        data_inicio: "",
        data_inicio_avaliacao: "",
        data_inicio_recuperacao: null,
        data_inicio_uc: "",
        data_previsto_fim_avaliacao: "",
        data_previsto_fim_recuperacao: null,
        data_previsto_inicio_avaliacao: null,
        data_previsto_inicio_recuperacao: null,
        detalhe_avaliacao: "",
        detalhe_curso: "",
        detalhe_curso_mae: "",
        detalhe_recuperacao: "",
        encerrado: true,
        gera_certificado: "S",
        html_apresentacao: null,
        id_curso: 2041,
        id_curso_uc_recuperacao: 2548,
        id_item_cobranca: 11,
        id_pai: 3,
        id_plano_ensino: 18,
        id_plataforma: 273,
        id_produto_vindi: null,
        id_programa_turma: 9,
        id_programa_turma_unidade: 13,
        id_registro_curso_plataforma: 3576,
        id_requisicao: null,
        id_secretaria_avaliacao_aluno_avaliacao: 40,
        id_secretaria_avaliacao_principal: 28,
        id_secretaria_avaliacao_recuperacao: 29,
        id_secretaria_avaliacao_uc: 194,
        id_secretaria_curso_programa: 1,
        id_secretaria_curso_programa_unidade: 4,
        id_secretaria_recuperacao_aluno_avaliacao: null,
        id_usuario_curso: 2752,
        id_usuario_responsavel: 6291,
        libera_automatica: "S",
        logo_curso: "",
        logo_curso_loja: null,
        logo_curso_mae: "",
        nome_curso: "",
        nome_curso_mae: "",
        nota_final_avaliacao: 5,
        nota_final_recuperacao: 0,
        nota_maxima: 10,
        nota_media: 7,
        path_gabarito_avaliacao: "",
        path_gabarito_recuperacao: "",
        preco: 100,
        publicado: true,
        quantidade: 1,
        recuperacao_configurada: true,
        reiniciar: 99999,
        sequencia: 3,
        tempo_atividade_avaliacao: "",
        tempo_atividade_recuperacao: null,
        tempo_executado_avaliacao: null,
        tempo_executado_recuperacao: null,
        tempo_limite_avaliacao: "",
        tempo_limite_recuperacao: "",
        tentativas_maxima: null,
        tentativas_maxima_avaliacao: 3,
        tentativas_maxima_avaliacao_aluno: 3,
        tentativas_maxima_recuperacao: 1,
        tentativas_maxima_recuperacao_aluno: 0,
        tipo_avaliacao: "N",
        titulo_avaliacao: "",
        titulo_recuperacao: "",
        url_pagamento: null,
        aprovado_avaliacao: false,
        aprovado_recuperacao: false
      },
      // Avaliação
      fastAvaliacaoConfiguracao: {
        final_modulo: false,
        id_avaliacao_configuracao: 0,
        id_resultado_media_aprovacao: 0,
        id_resultado_media_recuperacao: 0,
        id_resultado_passar_direto: 0,
        id_secretaria_avaliacao_uc: 0,
        liberar_prazo: 0,
        media_aprovacao: 0,
        media_recuperacao: 0,
        nota_maxima: 0,
        passar_direto: 0,
        tipo: "",
      },
      fastAvaliacaoPeso: 0.00,
      fastNotaAvaliacao: 0.00,
      fastNotaAvaliacaoArredondada: 0.00,
      fastAvaliacaoConfiguracaoArredondamentos: [],
      fastUsuarioAvaliacaoResultado: {
        aprovado: false,
        resultado: ""
      },
      chartDataAvaliacao: [0, 0],
      chartDataAvaliacaoPeso: [0, 0],
      chartAvaliacaoVisivel: false,
      chartOptionsAvaliacao: {
        colors: ["#1fcc1b", "#f42727"],
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Acertos", "Erros"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptionsAvaliacaoPeso: {
        colors: ["#1fcc1b", "#f42727"],
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Nota atingida", "Nota restante"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      fastRespostasAvaliacao: [],
      fastGabaritoAvaliacao: "",
      optionsArquivoGabaritoAvaliacao: {
        target: settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/upload_gabarito_admin",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: false,
        query: {
          id_secretaria_avaliacao_aluno: 0,
          id_usuario_aluno: 0,
          id_plataforma: this.$route.params.id_plataforma
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: ["pdf", "odt", "doc", "docx", "ppt", "pptx", "gif", "jpg", "jpeg", "png", "rar"],
        },
        parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      optionsArquivoGabaritoRecuperacao: {
        target: settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/upload_gabarito_admin",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: false,
        query: {
          id_secretaria_avaliacao_aluno: 0,
          id_usuario_aluno: 0,
          id_plataforma: this.$route.params.id_plataforma
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: ["pdf", "odt", "doc", "docx", "ppt", "pptx", "gif", "jpg", "jpeg", "png", "rar"],
        },
        parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept: "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/x-rar-compressed,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Recuperação
      fastRecuperacaoConfiguracao: {
        final_modulo: false,
        id_avaliacao_configuracao: 0,
        id_resultado_media_aprovacao: 0,
        id_resultado_media_recuperacao: 0,
        id_resultado_passar_direto: 0,
        id_secretaria_avaliacao_uc: 0,
        liberar_prazo: 0,
        media_aprovacao: 0,
        media_recuperacao: 0,
        nota_maxima: 0,
        passar_direto: 0,
        tipo: "",
      },
      fastRecuperacaoPeso: 0.00,
      fastNotaRecuperacao: 0.00,
      fastNotaRecuperacaoArredondada: 0.00,
      fastRecuperacaoConfiguracaoArredondamentos: [],
      fastUsuarioRecuperacaoResultado: {
        aprovado: false,
        resultado: ""
      },
      chartDataRecuperacao: [0, 0],
      chartDataRecuperacaoPeso: [0, 0],
      chartRecuperacaoVisivel: false,
      chartOptionsRecuperacao: {
        colors: ["#1fcc1b", "#f42727"],
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Acertos", "Erros"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptionsRecuperacaoPeso: {
        colors: ["#1fcc1b", "#f42727"],
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Nota atingida", "Nota restante"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      fastRespostasRecuperacao: [],
      fastGabaritoRecuperacao: "",
      fastSecretariaAvaliacaoAlunoEditar: {
        id_secretaria_avaliacao_aluno: 0,
        id_plataforma: this.$route.params.id_plataforma,
        tempo_atividade: "",
        tentativas_maxima: "",
        nota_final: "",
        aprovado: ""
      },
      fastSecretariaRecuperacaoAlunoEditar: {
        id_secretaria_avaliacao_aluno: 0,
        id_plataforma: this.$route.params.id_plataforma,
        tempo_atividade: "",
        tentativas_maxima: "",
        nota_final: "",
        aprovado: ""
      },
      fastUsuarioCurso: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      fastUsuarioCursoRecuperacao: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },      
      fastUnidadePorcentagem: 0,
      fastUnidadePorcentagemRecuperacao: 0,
      fastAtividadeFinalizar: {
        id_usuario_atividade: 0,
        id_usuario_aluno: 0,
        id_plataforma: 0,
        tipo: ""
      },
      fastAulaFinalizar: {
        id_aula: 0,
        id_usuario_aluno: 0,
        id_plataforma: 0,
        tipo: ""
      },
      fastUnidadeFinalizar: {
        id_curso: 0,
        id_usuario_aluno: 0,
        id_pessoa_aluno: 0,
        id_plataforma: 0,
        tipo: ""
      },
      //#region Auditoria
      fastAvaliacaoAlunoAuditoriaJustificativa: "",
      fastAvaliacaoAlunoAuditoria: [],
      fastAvaliacaoAlunoAuditoriaRecuperacao: [],
      //#endregion
      //#region Histórico de eventos
      anoBaseCalendario: new Date().getFullYear(),
      fasCalendarioNavegacao: {
        exibirCalendario: true,
        exibirDiaSelecionado: false
      },
      fastHistoricoDias: {
        total: [],
        loading: false
      },
      fastHistoricoDiaSelecionado: {
        total: [],
        filtro: [],
        paginacao: [],
        data_selecionada: "",
        loading: true
      },      
      //#endregion
      //#region Inconsistencias
      fastUsuarioCursoAulasReplicadas: [],
      fastUsuarioCursoAulaExcluir: {
        id_usuario_curso_aula: 0,
        id_plataforma: this.$route.params.id_plataforma
      },
      fastUsuarioCursoAtividadesReplicadas: [],
      fastUsuarioCursoAtividadeExcluir: {
        id_usuario_atividade: 0,
        id_plataforma: this.$route.params.id_plataforma
      },
      //#endregion
      //#region Boletim
      htmlToPdfOptions: {
        margin: 0.5,
        filename: 'Boletim.pdf',
        image: {
            type: 'jpeg',
            quality: 1
        },
        enableLinks: false,
        html2canvas: {
            scale: 1,
            scrollX: 0,
            scrollY: 0,
            useCORS: true,
            width: 960,
        },
        jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'portrait'
        },
        //pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      },
      boletim_html_template: '<div class="row"><div class="col-sm-12 col-md-12 col-lg-9"><div class="row"><div class="col-12"><div class="text-center p-4 border border-2 border-secondary"><h3>Extrato de Notas e Faltas Parcial</h3></div></div></div></div><div class="col-sm-12 col-md-12 col-lg-3">%img_logo_plataforma%</div><div class="col-sm-12 col-md-12 col-lg-12 mt-4"><div class="row"><div class="col-sm-12 col-md-12 col-lg-8"><span>Nome: <b>%nome_aluno%</b></span></div><div class="col-sm-12 col-md-12 col-lg-4"><span>CPF: <b>%cpf_aluno%</b></span></div></div><div class="row"><div class="col-sm-12 col-md-12 col-lg-4"><span>Nacionalidade: <b>%nacionalidade_aluno%</b></span></div><div class="col-sm-12 col-md-12 col-lg-4"><span>Naturalidade: <b>%naturalidade_aluno%</b></span></div><div class="col-sm-12 col-md-12 col-lg-4"><span>Nascimento: <b>%nascimento_aluno%</b></span></div></div><hr class="mt-2 mb-2"><div class="row"><div class="col-sm-12 col-md-12 col-lg-12"><span>Curso: <b>%nome_curso%, %nivel_curso%</b></span></div></div><div class="row"><div class="col-sm-12 col-md-12 col-lg-12"><span>%descricao_legal%</span></div></div><div class="row"><div class="col-12 table-responsive mt-4"><table class="table"><thead><tr><th scope="col">ANO</th><th scope="col">DISCIPLINA</th><th scope="col">FALTAS POSSÍVEIS</th><th scope="col">FALTAS</th><th scope="col">CH</th><th scope="col">NOTA</th><th scope="col">SITUAÇÃO</th></tr></thead><tbody>%tbody_boletim%</tbody></table></div></div></div></div>',
      boletim_modulos_selecionados: []
      //#endregion
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.$store.state.fastCarregando = false;
            this.getAlunos(this.$route.params.id_plataforma, this.$route.params.id_turma)

            this.getFastTurma().then(async (turma) => {
              this.fastTurma = turma
            })
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastTurma() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_turma/lista?id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_turma=" +
              this.$route.params.id_turma,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          if (json.length) {
            resolve(json[0]);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    async getAlunos(id_plataforma, id_turma) {
      this.promiseGetFastApi(
        "api/fast_secretaria_matricula_aluno/lista_alunos",
        "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma,
      )
        .then((obj) => {
          if (obj.length) {
            this.fastUsuariosAlunos = obj
            this.fastUsuariosAlunosFiltro = obj

            if (this.getUrlParameter("id_matricula")) {
              obj.forEach(a => {
                if (a.id_matricula == parseInt(this.getUrlParameter("id_matricula"))) this.exibeEstatisticasAluno(a)
              })
            }
          } else {
            this.fastUsuariosAlunos = []
            this.fastUsuariosAlunosFiltro = []
          }
          this.fastUsuariosAlunosLoading = false
        })
        .catch((e) => {
          this.exibeToasty("Erro", "error");
          this.fastUsuariosAlunosLoading = false
        });
    },
    filtraAlunos(e) {
      let text = e.target.value;
      this.fastUsuariosAlunosFiltro = this.fastUsuariosAlunos.filter((e) => {
        return ((e.nome_razao ? e.nome_razao.toLowerCase().indexOf(text.toLowerCase()) != "-1" : false) || (e.sobrenome_fantasia ? e.sobrenome_fantasia.toLowerCase().indexOf(text.toLowerCase()) != "-1" : false))
      });
    },
    exibeEstatisticasAluno(aluno){
      if (!aluno.matricula_por_programa && !aluno.matricula_por_unidade) {
        // Traz todos os módulos
      } else if (aluno.matricula_por_programa) {
        // Traz somente os módulos que aluno está matriculado
      } else if (aluno.matricula_por_unidade) {
        // Traz somente os módulos em que existem unidades curriculares que o aluno está matriculado
      }
      this.fastAluno = aluno
      this.alteraFastNavegacao('exibirEstatisticas')
      
      this.fastAlunoModulos.loading = true
      this.fastAlunoModulos.total = []
      this.promiseGetFastApi("api/fast_secretaria_turma_programa/lista_aluno", "id_plataforma=" + aluno.id_plataforma + "&id_matricula=" + aluno.id_matricula + "&id_turma=" + aluno.id_turma)
      .then((res) => {
        if (res.length) {
          this.fastAlunoModulos.total = res            
        } 
        this.fastAlunoModulos.loading = false
      })
      .catch((e) => {
        this.exibeToasty("Erro", "error");
        this.fastAlunoModulos.loading = false
      });
        
    },
    exibirDetalhesModulo(modulo) {
      this.fastAlunoModulo = modulo

      this.fastNavegacaoEstatisticas.exibirModulos = false
      this.fastNavegacaoEstatisticas.exibirModulo = true
      this.fastNavegacaoEstatisticas.exibirUnidade = false      

      this.fastAlunoUnidades.loading = true
      this.fastAlunoUnidades.total = []
      this.promiseGetFastApi("api/fast_secretaria_turma_programa/lista_aluno_unidades", "id_plataforma=" + this.fastAluno.id_plataforma + "&id_matricula=" + this.fastAluno.id_matricula + "&id_programa_turma=" + modulo.id_programa_turma)
      .then((res) => {
        if (res.length) {
          this.fastAlunoUnidades.total = res            
        } 
        this.fastAlunoUnidades.loading = false
      })
      .catch((e) => {
        this.exibeToasty("Erro", "error");
        this.fastAlunoUnidades.loading = false
      });

    },
    exibirDetalhesUnidade(unidade) {
      this.fastAlunoUnidade = unidade

      this.limpaObjetos()

      this.fastNavegacaoEstatisticas.exibirModulos = false
      this.fastNavegacaoEstatisticas.exibirModulo = false
      this.fastNavegacaoEstatisticas.exibirUnidade = true  
      
      if (unidade.id_secretaria_avaliacao_uc) {
        this.getAvaliacaoConfiguracoes(unidade.id_secretaria_avaliacao_uc)
      }
      
      if (unidade.id_secretaria_avaliacao_principal && unidade.id_secretaria_avaliacao_aluno_avaliacao) {
        this.getPesoAvaliacao(unidade.id_secretaria_avaliacao_principal, "A")
        this.getSecretariaAvaliacaoAlunoAuditoria(unidade.id_plataforma, unidade.id_secretaria_avaliacao_aluno_avaliacao, "A")
        // Gabarito
        this.optionsArquivoGabaritoAvaliacao.query.id_secretaria_avaliacao_aluno = unidade.id_secretaria_avaliacao_aluno_avaliacao
        this.optionsArquivoGabaritoAvaliacao.query.id_usuario_aluno = this.fastAluno.id_usuario

        this.fastSecretariaAvaliacaoAlunoEditar.id_secretaria_avaliacao_aluno = unidade.id_secretaria_avaliacao_aluno_avaliacao
        this.fastSecretariaAvaliacaoAlunoEditar.tempo_atividade = unidade.tempo_atividade_avaliacao
        this.fastSecretariaAvaliacaoAlunoEditar.tentativas_maxima = unidade.tentativas_maxima_avaliacao_aluno
        this.fastSecretariaAvaliacaoAlunoEditar.nota_final = unidade.nota_final_avaliacao
        unidade.aprovado_avaliacao ? this.fastSecretariaAvaliacaoAlunoEditar.aprovado = 1 : this.fastSecretariaAvaliacaoAlunoEditar.aprovado = 0
      }
      if (unidade.id_secretaria_avaliacao_recuperacao && unidade.id_secretaria_recuperacao_aluno_avaliacao) {
        this.getPesoAvaliacao(unidade.id_secretaria_avaliacao_recuperacao, "R")
        this.getSecretariaAvaliacaoAlunoAuditoria(unidade.id_plataforma, unidade.id_secretaria_recuperacao_aluno_avaliacao, "R")
        // Gabarito
        this.optionsArquivoGabaritoRecuperacao.query.id_secretaria_avaliacao_aluno = unidade.id_secretaria_recuperacao_aluno_avaliacao
        this.optionsArquivoGabaritoRecuperacao.query.id_usuario_aluno = this.fastAluno.id_usuario

        this.fastSecretariaRecuperacaoAlunoEditar.id_secretaria_avaliacao_aluno = unidade.id_secretaria_recuperacao_aluno_avaliacao
        this.fastSecretariaRecuperacaoAlunoEditar.tempo_atividade = unidade.tempo_atividade_recuperacao
        this.fastSecretariaRecuperacaoAlunoEditar.tentativas_maxima = unidade.tentativas_maxima_recuperacao_aluno
        this.fastSecretariaRecuperacaoAlunoEditar.nota_final = unidade.nota_final_recuperacao
        unidade.aprovado_recuperacao ? this.fastSecretariaRecuperacaoAlunoEditar.aprovado = 1 : this.fastSecretariaRecuperacaoAlunoEditar.aprovado = 0
        if (unidade.id_curso_uc_recuperacao) {
          // Busca porcentagem de progresso da UC de recuperação
          this.getProgressoUnidade(unidade.id_plataforma, unidade.id_curso_uc_recuperacao, this.fastAluno.id_usuario, "R")

          // Busca aulas da UC de recuperação
          this.fastUsuarioCursoRecuperacao.total = []
          this.fastUsuarioCursoRecuperacao.filtro = []
          this.fastUsuarioCursoRecuperacao.loading = true
          this.getUsuarioCursoAulas(unidade.id_plataforma, this.fastAluno.id_usuario, unidade.id_curso_uc_recuperacao)
          .then(res => {
            if (res.length) {
              res.forEach(e => {
                e.atividades = {
                  total: [],
                  filtro: [],
                  loading: true
                }
              })
              this.fastUsuarioCursoRecuperacao.total = res
              this.fastUsuarioCursoRecuperacao.filtro = res
            }
            this.fastUsuarioCursoRecuperacao.loading = false
          })
          .catch(e => { 
            console.log(e)
            this.fastUsuarioCursoRecuperacao.loading = false
          })
        }
        
      }

      // Busca aulas da UC principal
      this.fastUsuarioCurso.total = []
      this.fastUsuarioCurso.filtro = []
      this.fastUsuarioCurso.loading = true
      this.getUsuarioCursoAulas(unidade.id_plataforma, this.fastAluno.id_usuario, unidade.id_curso)
      .then(res => {
        const res2 = res
        //console.log("res2", res2)
        if (res.length) {
          res.forEach(e => {

            let ids_aula = 0
            res2.forEach(e2 => { if (e.id_aula == e2.id_aula) ids_aula++ })
            if (ids_aula > 1) e.aula_replicada = true //this.exibeToasty(`Aula ${e.sequencia_aula} inconsistente`, "error")

            e.atividades = {
              total: [],
              filtro: [],
              loading: true
            }
          })
          this.fastUsuarioCurso.total = res
          this.fastUsuarioCurso.filtro = res
        }
        this.fastUsuarioCurso.loading = false
      })
      .catch(e => { 
        console.log(e)
        this.fastUsuarioCurso.loading = false
      })
      // Busca porcentagem de progresso da UC principal
      this.getProgressoUnidade(unidade.id_plataforma, unidade.id_curso, this.fastAluno.id_usuario, "A")
      
    },
    formataTempoLimite(tempo_atividade) {
      let horas = parseInt(tempo_atividade.split(":")[0]);
      let minutos = parseInt(tempo_atividade.split(":")[1]);
      let tempo_limite = "";
      if (horas) {
        if (horas == 1) tempo_limite = "1 hora";
        else tempo_limite = horas + " horas";
        if (minutos) {
          tempo_limite += " e " + minutos + " minutos";
        }
      } else if (minutos) {
        tempo_limite = minutos + " minutos";
      } else {
        tempo_limite = "Ilimitado";
      }

      return tempo_limite;
    },
    async getAvaliacaoConfiguracoes(id_secretaria_avaliacao_uc){  
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_configuracao/lista", "id_secretaria_avaliacao_uc=" + id_secretaria_avaliacao_uc)
      .then(res => {  
        if (res.length) {
          res.forEach(c => {
            if (c.tipo == "A") {
              this.fastAvaliacaoConfiguracao = c
              this.getAvaliacaoArredondamento(c.id_avaliacao_configuracao, "A") 
              //this.calculaDiasPrazo()
            } else {
              this.fastRecuperacaoConfiguracao = c
              this.getAvaliacaoArredondamento(c.id_avaliacao_configuracao, "R") 
              //this.calculaDiasPrazo()
            }
               
          })          
        } else {
          this.exibeToasty("Nenhuma avaliação encontrada", "info")
        }
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar configurações da avaliação", "error")
        console.log(e)
      })
    },
    async getPesoAvaliacao(id_secretaria_avaliacao, tipo) {
      let prova_peso = 0;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/questoes/lista_peso?id_secretaria_avaliacao=" + id_secretaria_avaliacao, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          prova_peso = obj[0].prova_peso;
        }
        if (tipo == "A") this.fastAvaliacaoPeso = prova_peso;
        else
        this.fastRecuperacaoPeso = prova_peso;
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async getAvaliacaoArredondamento(id_avaliacao_configuracao, tipo){      
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_arredondamento/lista", "id_avaliacao_configuracao=" + id_avaliacao_configuracao)
      .then(res => {      
        if (res.length) {
          if (tipo == "A") this.fastAvaliacaoConfiguracaoArredondamentos = res   
          else 
          this.fastRecuperacaoConfiguracaoArredondamentos = res 
        }
        else {
          if (tipo == "A") this.fastAvaliacaoConfiguracaoArredondamentos = []
          else 
          this.fastRecuperacaoConfiguracaoArredondamentos = []
        }       

        if (this.fastAlunoUnidade.data_fim_avaliacao && tipo == "A") {
            this.getResultadoAvaliacao(this.fastAlunoUnidade.id_secretaria_avaliacao_aluno_avaliacao, tipo)
            this.getRespostasAvaliacao(this.$route.params.id_plataforma, this.fastAlunoUnidade.id_secretaria_avaliacao_aluno_avaliacao, tipo)
        }

        if (this.fastAlunoUnidade.data_fim_recuperacao && tipo == "R") {
            this.getResultadoAvaliacao(this.fastAlunoUnidade.id_secretaria_recuperacao_aluno_avaliacao, tipo)
            this.getRespostasAvaliacao(this.$route.params.id_plataforma, this.fastAlunoUnidade.id_secretaria_recuperacao_aluno_avaliacao, tipo)
        }
          
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar arredondamentos", "error")
        console.log(e)
      })
    },
    async getResultadoAvaliacao(id_secretaria_avaliacao_aluno, tipo) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/questoes/lista_admin?id_secretaria_avaliacao_aluno=" + id_secretaria_avaliacao_aluno + "&id_usuario_aluno=" + this.fastAluno.id_usuario + "&id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          let acertos = 0;
          let erros = 0;
          let nota = 0;
          let peso_total = 0;
          obj.forEach((q) => {
            peso_total += q.questao_peso;
            if (q.acertou != "S") {
              erros++;
            } else {
              nota += q.questao_peso;
              acertos++;
            }
          })

          if (tipo == "A") {
            this.chartAvaliacaoVisivel = true;
            this.fastPesoAvaliacao = peso_total ? parseFloat(peso_total).toFixed(2) : 0
            this.fastNotaAvaliacao = nota ? parseFloat(nota).toFixed(2) : 0
            this.fastNotaAvaliacaoArredondada = 0
            if (this.fastAvaliacaoConfiguracaoArredondamentos.length) {
              this.fastAvaliacaoConfiguracaoArredondamentos.forEach(e => {
                if (this.fastNotaAvaliacao >= e.faixa_inicial && this.fastNotaAvaliacao <= e.faixa_final) this.fastNotaAvaliacaoArredondada = e.nota
              })
            }           
            if (this.fastNotaAvaliacaoArredondada) {
              if (this.fastNotaAvaliacaoArredondada >= this.fastAvaliacaoConfiguracao.media_aprovacao) this.fastUsuarioAvaliacaoResultado.aprovado = true  
              else
              this.fastUsuarioAvaliacaoResultado.aprovado = false
            } else {
              if (this.fastNotaAvaliacao >= this.fastAvaliacaoConfiguracao.media_aprovacao) this.fastUsuarioAvaliacaoResultado.aprovado = true  
              else
              this.fastUsuarioAvaliacaoResultado.aprovado = false 
            }
            this.chartDataAvaliacao = [acertos, erros]
            this.chartDataAvaliacaoPeso = [nota, peso_total - nota]
          } else {
            this.chartRecuperacaoVisivel = true;
            this.fastPesoRecuperacao = peso_total ? parseFloat(peso_total).toFixed(2) : 0
            this.fastNotaRecuperacao = nota ? parseFloat(nota).toFixed(2) : 0
            this.fastNotaRecuperacaoArredondada = 0
            if (this.fastRecuperacaoConfiguracaoArredondamentos.length) {
              this.fastRecuperacaoConfiguracaoArredondamentos.forEach(e => {
                if (this.fastNotaRecuperacao >= e.faixa_inicial && this.fastNotaRecuperacao <= e.faixa_final) this.fastNotaRecuperacaoArredondada = e.nota
              })
            }           
            if (this.fastNotaRecuperacaoArredondada) {
              if (this.fastNotaRecuperacaoArredondada >= this.fastRecuperacaoConfiguracao.media_recuperacao) this.fastUsuarioRecuperacaoResultado.aprovado = true  
              else
              this.fastUsuarioRecuperacaoResultado.aprovado = false
            } else {
              if (this.fastNotaRecuperacao >= this.fastRecuperacaoConfiguracao.media_recuperacao) this.fastUsuarioRecuperacaoResultado.aprovado = true  
              else
              this.fastUsuarioRecuperacaoResultado.aprovado = false 
            }
            this.chartDataRecuperacao = [acertos, erros]
            this.chartDataRecuperacaoPeso = [nota, peso_total - nota]
          }

        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async calculaDiasPrazo(){
      let data_permitida = ""

      if (this.getUrlParameter("tipo") == "A") {
        this.buscaDadosUnidadeCurricular(this.fastAvaliacao.id_curso).then(u => {
          if (u.length) {            
            u = u[0]            
            if (u.data_inicio) this.fastRequisitosAvaliacao.data_iniciou = u.data_inicio.split("T")[0]      
            
          }
        }).then(() => {
          if (this.fastRequisitosAvaliacao.data_iniciou) {
            let inicio = new Date(this.fastRequisitosAvaliacao.data_iniciou.split('-')[1] + '-' + this.fastRequisitosAvaliacao.data_iniciou.split('-')[2] + '-' + this.fastRequisitosAvaliacao.data_iniciou.split('-')[0]);
            data_permitida = this.addDays(inicio, this.fastAvaliacaoConfiguracao.liberar_prazo)
            
            this.getFastHoraServidor().then(() => {
              let dataAtual = this.$store.state.fastDataAtualServidor
              if (dataAtual > data_permitida && !this.fastAvaliacao.final_modulo) {
                this.fastRequisitosAvaliacao.disponivel = true
              } else {
                this.fastRequisitosAvaliacao.disponivel = false
              }
              this.fastRequisitosAvaliacao.data_disponivel = this.dataFormatada(data_permitida)

            })
            
          } else {
            this.fastRequisitosAvaliacao.data_disponivel = "iniciar UC"
          }   
        }).catch(e => {
          this.fastRequisitosAvaliacao.data_disponivel = "iniciar UC"
        })
      } else {
        if (this.fastAvaliacao.id_curso_uc_recuperacao) {
          this.buscaDadosUnidadeCurricular(this.fastAvaliacao.id_curso_uc_recuperacao).then(u => {
            if (u.length) {
              u = u[0]              
              if (u.data_inicio) this.fastRequisitosRecuperacao.data_iniciou = u.data_inicio.split("T")[0]
            }
            

          }).then(() => {
            if (this.fastRequisitosRecuperacao.data_iniciou) {
            let inicio = new Date(this.fastRequisitosAvaliacao.data_iniciou.split('-')[1] + '-' + this.fastRequisitosAvaliacao.data_iniciou.split('-')[2] + '-' + this.fastRequisitosAvaliacao.data_iniciou.split('-')[0]);
            data_permitida = this.addDays(inicio, this.fastAvaliacaoConfiguracao.liberar_prazo)

            
            this.getFastHoraServidor().then(() => {
              let dataAtual = this.$store.state.fastDataAtualServidor
              if (dataAtual > data_permitida && !this.fastAvaliacao.final_modulo) {
                this.fastRequisitosRecuperacao.disponivel = true
              } else {
                this.fastRequisitosRecuperacao.disponivel = false
              }
              this.fastRequisitosRecuperacao.data_disponivel = this.dataFormatada(data_permitida)
            })
            
          } else {
            this.fastRequisitosRecuperacao.data_disponivel = "iniciar UC"
          }   
          }).catch(e => {
            this.fastRequisitosRecuperacao.data_disponivel = "iniciar UC"
          })
        }
        
      }

         
    }, 
    limpaObjetos(){
      this.chartAvaliacaoVisivel = false      
      this.chartDataAvaliacao = [0, 0]
      this.chartDataAvaliacaoPeso = [0, 0]
      this.fastGabaritoAvaliacao = ""

      this.chartDataRecuperacao = [0, 0]
      this.chartDataRecuperacaoPeso = [0, 0]
      this.chartRecuperacaoVisivel = false
      this.fastGabaritoRecuperacao = ""
      
      this.fastNotaAvaliacaoArredondada = 0.00
    },
    //#region Estatísticas avaliação
    async getRespostasAvaliacao(id_plataforma, id_secretaria_avaliacao_aluno, tipo){
      if (tipo == "A") this.fastGabaritoAvaliacao = ""
      if (tipo == "R") this.fastGabaritoRecuperacao = ""
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_aluno/questoes/lista_respostas", `id_plataforma=${id_plataforma}&id_secretaria_avaliacao_aluno=${id_secretaria_avaliacao_aluno}`)
        .then((obj) => {
          if (obj.length) {
            const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
              return obj.find((a) => a.id_questao === id_questao);
            });

            questoesUnicas.forEach((a, index) => {
              obj.forEach((q, index2) => {
                if (a.id_questao == q.id_questao) {
                  if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                  questoesUnicas[index].alternativas.push(q);
                }
              });
            });

            if (tipo == "A") {
              this.fastRespostasAvaliacao = questoesUnicas
              if (questoesUnicas[0].path_gabarito) this.fastGabaritoAvaliacao = questoesUnicas[0].path_gabarito
              //this.showModal('modalRespostasAvaliacao')
            } else if (tipo == "R"){
              this.fastRespostasRecuperacao = questoesUnicas
              if (questoesUnicas[0].path_gabarito) this.fastGabaritoRecuperacao = questoesUnicas[0].path_gabarito
              //this.showModal('modalRespostasRecuperacao')
            }
            
          } else {
            this.exibeToasty("Nenhuma resposta encontrada", "error")
            
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error")
        });
    },
    async atualizaAvaliacaoAluno(tipo){
      let fast_secretaria_avaliacao_aluno = Object.assign({}, this.fastSecretariaAvaliacaoAlunoEditar)
      if (tipo == "R") fast_secretaria_avaliacao_aluno = Object.assign({}, this.fastSecretariaRecuperacaoAlunoEditar)

      let erros = []
      if (!this.fastAvaliacaoAlunoAuditoriaJustificativa) erros.push("Insira uma justificativa")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        if (!fast_secretaria_avaliacao_aluno.aprovado || fast_secretaria_avaliacao_aluno.aprovado == "0") fast_secretaria_avaliacao_aluno.aprovado = false 
        else fast_secretaria_avaliacao_aluno.aprovado = true

        let obj = {
          fast_secretaria_avaliacao_aluno: fast_secretaria_avaliacao_aluno,
          justificativa: this.fastAvaliacaoAlunoAuditoriaJustificativa
        }

        this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao_aluno/atualiza")
        .then((res) => {
            if (res.length) {
              this.exibeToasty("Dados atualizados com sucesso", "success")
              if (tipo == "A") {
                this.fastAlunoUnidade.tempo_atividade_avaliacao = res[0].tempo_atividade
                this.fastAlunoUnidade.tentativas_maxima_avaliacao_aluno = res[0].tentativas_maxima
                this.fastAlunoUnidade.nota_final_avaliacao = res[0].nota_final
                this.fastAlunoUnidade.aprovado_avaliacao = res[0].aprovado
                this.hideModal('modalEditarAvaliacao')

                this.getSecretariaAvaliacaoAlunoAuditoria(this.fastAlunoUnidade.id_plataforma, this.fastAlunoUnidade.id_secretaria_avaliacao_aluno_avaliacao, "A")
              } else {
                this.fastAlunoUnidade.tempo_atividade_recuperacao = res[0].tempo_atividade
                this.fastAlunoUnidade.tentativas_maxima_recuperacao_aluno = res[0].tentativas_maxima
                this.fastAlunoUnidade.nota_final_recuperacao = res[0].nota_final
                this.fastAlunoUnidade.aprovado_recuperacao = res[0].aprovado
                this.hideModal('modalEditarRecuperacao')

                this.getSecretariaAvaliacaoAlunoAuditoria(this.fastAlunoUnidade.id_plataforma, this.fastAlunoUnidade.id_secretaria_recuperacao_aluno_avaliacao, "R")
              }           
              this.fastAvaliacaoAlunoAuditoriaJustificativa = "" 
            } else {
              this.exibeToasty("Erro ao atualizar dados", "error")
            }          
        }).catch((e) => {
            this.exibeToasty("Erro ao atualizar dados", "error")
        })
      }
    },
    async getUsuarioCursoAulas(id_plataforma, id_usuario, id_curso) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_usuario_curso/lista_aulas?id_plataforma=" +
              id_plataforma +
              "&id_usuario_aluno=" +
              id_usuario +
              "&id_curso=" +
              id_curso,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          if (json.length) {
            resolve(json);
          } else {
            reject(0);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    async getUsuarioCursoAulaAtividades(id_plataforma, id_usuario, id_aula, tipo) {
      this.promiseGetFastApi("api/fast_usuario_curso/lista_aula_atividades", `id_plataforma=${id_plataforma}&id_usuario_aluno=${id_usuario}&id_aula=${id_aula}`)
      .then(res => {
        const res2 = res
        res.forEach(e => {
          let ids_atividades = 0
          res2.forEach(e2 => { if (e.id_atividade == e2.id_atividade) ids_atividades++ })
          if (ids_atividades > 1) e.atividade_replicada = true 
        })        

        if (tipo == "A") {
          this.fastUsuarioCurso.total.forEach(e => {
            if (e.id_aula == id_aula) {
              e.atividades.total = res
              e.atividades.filtro = res
              e.atividades.loading = false
            }
          })
        } else {
          this.fastUsuarioCursoRecuperacao.total.forEach(e => {
            if (e.id_aula == id_aula) {
              e.atividades.total = res
              e.atividades.filtro = res
              e.atividades.loading = false              
            }
          })
        }
      })
      .catch(e => {
        console.log(e)
      })      
    },
    async getProgressoUnidade(id_plataforma, id_curso, id_usuario_aluno, tipo){
      if (tipo == "A") this.fastUnidadePorcentagem = 0
      else
        this.fastUnidadePorcentagemRecuperacao = 0
      this.promiseGetFastApi("api/fast_usuario_curso/porcentagem_concluido_admin", "id_plataforma=" + id_plataforma + "&id_curso=" + id_curso + "&id_usuario_aluno=" + id_usuario_aluno)
      .then((res) => {
        if (res.length) {
            if (tipo == "A") this.fastUnidadePorcentagem =  res[0].porcentagem_conclusao
            else
              this.fastUnidadePorcentagemRecuperacao = res[0].porcentagem_conclusao
        } 
      })
      .catch((e) => {
        this.exibeToasty("Erro", "error")
      });
    },
    formataTipoAtividade(e) {
      switch (e) {
        case "RF":
          return "Arquivo";
          break;
        case "AD":
          return "Audio";
          break;
        case "VA":
          return "Video Aula";
          break;
        case "PS":
          return "Pesquisa";
          break;
        case "AV":
          return "Avaliação";
          break;
        case "SM":
          return "Simulado";
          break;
        case "QZ":
          return "Quiz";
          break;
        case "LV":
          return "Live";
          break;
        case "UP":
          return "Upload";
          break;
        default:
          return "Desconhecido";
      }
    },
    exibeModalFinalizarAtividade(atividade, tipo){
      this.fastAtividadeFinalizar.id_usuario_atividade = atividade.id_usuario_atividade
      this.fastAtividadeFinalizar.id_usuario_aluno = this.fastAluno.id_usuario
      this.fastAtividadeFinalizar.id_plataforma = this.fastAluno.id_plataforma
      this.fastAtividadeFinalizar.tipo = tipo
      this.showModal('modalFinalizarAtividade')
    },
    async finalizarAtividade(){
      this.promisePostFastApi(this.fastAtividadeFinalizar, "api/fast_usuario_curso_aula_atividade/atualiza_admin")
      .then((res) => {
        if (res.length) {
          let atualizacao_confirmada = false
          if (this.fastAtividadeFinalizar.tipo == "A") {
            this.fastUsuarioCurso.total.forEach(aula => {
              if (aula.atividades.total) {
                aula.atividades.total.forEach(atividade => {
                  if (atividade.id_usuario_atividade == this.fastAtividadeFinalizar.id_usuario_atividade) {
                    atividade.data_fim = res[0].data_fim
                    atualizacao_confirmada = true
                  }
                })
              }
            })
          } else {
            this.fastUsuarioCursoRecuperacao.total.forEach(aula => {
              if (aula.atividades.total) {
                aula.atividades.total.forEach(atividade => {
                  if (atividade.id_usuario_atividade == this.fastAtividadeFinalizar.id_usuario_atividade) {
                    atividade.data_fim = res[0].data_fim
                    atualizacao_confirmada = true
                  }
                })
              }
            })
          }
          if (atualizacao_confirmada = true){
            this.hideModal('modalFinalizarAtividade')
            this.exibeToasty("Atualização concluída com sucesso", "success")
          } else {
            this.exibeToasty("Erro ao atualizar atividade", "error")
          }         
        } else {
          this.exibeToasty("Erro ao atualizar atividade", "error")
        }  
        
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar atividade", "error")
      });
    },
    exibeModalFinalizarAula(aula, tipo){
      this.fastAulaFinalizar.id_aula = aula.id_aula
      this.fastAulaFinalizar.id_usuario_aluno = this.fastAluno.id_usuario
      this.fastAulaFinalizar.id_plataforma = this.fastAluno.id_plataforma
      this.fastAulaFinalizar.tipo = tipo
      this.showModal('modalFinalizarAula')
    },
    async finalizarAula(){
      this.promisePostFastApi(this.fastAulaFinalizar, "api/fast_usuario_curso_aula/finaliza_admin")
      .then((res) => {
        if (res.length) {
          let atualizacao_confirmada = false
          if (this.fastAulaFinalizar.tipo == "A") {
            this.fastUsuarioCurso.total.forEach(aula => {
              if (aula.id_aula == this.fastAulaFinalizar.id_aula) {
                aula.status = res[0].status
                atualizacao_confirmada = true
              }
            })
          } else {
            this.fastUsuarioCursoRecuperacao.total.forEach(aula => {
              if (aula.id_aula == this.fastAulaFinalizar.id_aula) {
                aula.status = res[0].status
                atualizacao_confirmada = true
              }
            })
          }
          if (atualizacao_confirmada = true){
            this.hideModal('modalFinalizarAula')
            this.exibeToasty("Atualização concluída com sucesso", "success")
          } else {
            this.exibeToasty("Erro ao atualizar aula", "error")
          }         
        } else {
          this.exibeToasty("Erro ao atualizar aula", "error")
        }  
        
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar aula", "error")
      });
    },
    exibeModalFinalizarUnidade(unidade, tipo){
      if (tipo == "A") this.fastUnidadeFinalizar.id_curso = unidade.id_curso
      else
        this.fastUnidadeFinalizar.id_curso = unidade.id_curso_uc_recuperacao

      this.fastUnidadeFinalizar.id_usuario_aluno = this.fastAluno.id_usuario
      this.fastUnidadeFinalizar.id_plataforma = this.fastAluno.id_plataforma
      this.fastUnidadeFinalizar.id_pessoa_aluno = this.fastAluno.id_pessoa_aluno      
      this.fastUnidadeFinalizar.tipo = tipo
      this.showModal('modalFinalizarUnidade')
    },
    async finalizarUnidade(){
      this.promisePostFastApi(this.fastUnidadeFinalizar, "api/fast_usuario_curso/finaliza_admin")
      .then((res) => {
        if (res.length) {
          let atualizacao_confirmada = false

          if (this.fastUnidadeFinalizar.tipo == "A") {
            this.fastAlunoUnidades.total.forEach(u => {
              if (u.id_curso == this.fastUnidadeFinalizar.id_curso) {
                u.encerrado = 1
                atualizacao_confirmada = true
              }
            })
          } else {
            atualizacao_confirmada = true
          }

          if (atualizacao_confirmada = true){
            this.hideModal('modalFinalizarUnidade')
            this.exibeToasty("Atualização concluída com sucesso", "success")
          } else {
            this.exibeToasty("Erro ao atualizar unidade curricular", "error")
          }         
        } else {
          this.exibeToasty("Erro ao atualizar unidade curricular", "error")
        }  
        
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar curso", "error")
      });
    },
    verificaAtividadesFinalizadas(atividades){
      let finalizadas = false
      if (atividades.length) {
        let total = 0
        atividades.forEach(a => {
          if (a.data_fim) total++
        })
        if (atividades.length == total) finalizadas = true
      }
      return finalizadas
    },
    verificaAulasFinalizadas(aulas, tipo) {
      let permite_finalizar = false
      let finalizadas = 0
      if (tipo == "A") {
        this.fastUsuarioCurso.total.forEach(aula => {
          if (aula.status == "F") {
            finalizadas++
          }
        })
        if (finalizadas == this.fastUsuarioCurso.total.length) {
          permite_finalizar = true
        }
      } else {
        this.fastUsuarioCursoRecuperacao.total.forEach(aula => {
          if (aula.status == "F") {
            finalizadas++
          }
        })
        if (finalizadas == this.fastUsuarioCursoRecuperacao.total.length) {
          permite_finalizar = true
        }
      }
      return permite_finalizar
    },
    //#endregion
    //#region Auditoria
    getSecretariaAvaliacaoAlunoAuditoria(id_plataforma, id_secretaria_avaliacao_aluno, tipo){
      if (tipo == "A" ) this.fastAvaliacaoAlunoAuditoria = []
      if (tipo == "R" ) this.fastAvaliacaoAlunoAuditoriaRecuperacao = []
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_aluno_auditoria/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_avaliacao_aluno=" + id_secretaria_avaliacao_aluno)
      .then((res) => {
        if (res.length) {
            if (tipo == "A") this.fastAvaliacaoAlunoAuditoria =  res
            else
              this.fastAvaliacaoAlunoAuditoriaRecuperacao = res
        } 
      })
      .catch((e) => {
        this.exibeToasty("Erro", "error")
      })
    },
    async buscaLocalizacaoIP(usuario) {
      try {
        let resp = await fetch(
          "https://ipapi.co/" + usuario.endereco_ip + "/json/",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        if (!json.error) {
          usuario.country_name = json.country_name;
          usuario.region = json.region;
          usuario.city = json.city;
        } else {
          usuario.country_name = "Não identificado";
          usuario.region = "Não identificado";
          usuario.city = "Não identificado";
        }
      } catch (e) {
        console.log(e);
      }
    },
    detectaBrowser(user_agent) {
      var isOpera =
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0;
      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== "undefined";
      // Safari 3.0+ "[object HTMLElementConstructor]"
      var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && safari.pushNotification)
        );
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode;
      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia;
      // Chrome 1 - 79
      var isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);
      // Edge (based on chromium) detection
      var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;
      // Blink engine detection
      var isBlink = (isChrome || isOpera) && !!window.CSS;

      if (isOpera) return "Opera";
      else if (isFirefox) return "Firefox";
      else if (isSafari) return "Safari";
      else if (isIE) return "Internet Explorer";
      else if (isEdge) return "Edge";
      else if (isChrome) return "Chrome";
      else return "Não identificado";
    },
    //#endregion
    //#region Histórico de eventos
    exibeModalExibirCalendarioHistoricoFiltro(id_turma, id_programa_turma, id_programa_turma_unidade){
      this.fasCalendarioNavegacao.exibirCalendario = true
      this.fasCalendarioNavegacao.exibirDiaSelecionado = false

      if (!id_turma) id_turma = 0
      if (!id_programa_turma) id_programa_turma = 0
      if (!id_programa_turma_unidade) id_programa_turma_unidade = 0

      let objArray = []
      this.fastHistoricoDias.total = objArray
      this.fastHistoricoDias.loading = true

      this.promiseGetFastApi("api/fast_secretaria_aluno_auditoria/lista_dias_filtro", `id_usuario=${this.fastAluno.id_usuario}&id_plataforma=${this.$route.params.id_plataforma}&id_turma=${id_turma}&id_programa_turma=${id_programa_turma}&id_programa_turma_unidade=${id_programa_turma_unidade}&ano=${this.anoBaseCalendario}&mes=0&dia=0`)
      .then((res) => {
        if (res.length) { 
          res.forEach(e => {
            objArray.push({
              data_evento: `${e.ano}-${e.mes}-${e.dia}T00:00:00`
            })
          })   
          this.fastHistoricoDias.total = objArray          
          
        }
        this.$bvModal.show('modalExibirCalendarioHistoricoTurma')
        this.fastHistoricoDias.loading = false
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastHistoricoDias.loading = false
      })
      
      //this.showModal('modalExibirCalendarioHistoricoTurma')
      
      
    },
    diaCalendarioClicadoTurma(cal){
      this.fastHistoricoDiaSelecionado.data_selecionada = cal.date

      this.fastHistoricoDiaSelecionado.loading = true
      this.fasCalendarioNavegacao.exibirCalendario = false
      this.fasCalendarioNavegacao.exibirDiaSelecionado = true
      this.fastHistoricoDiaSelecionado.total = []
      this.fastHistoricoDiaSelecionado.filtro = []

      let id_turma = 0
      let id_programa_turma = 0
      let id_programa_turma_unidade = 0
      if (this.fastNavegacaoEstatisticas.exibirModulos) {
        id_turma = this.fastTurma.id_turma
      } else if (this.fastNavegacaoEstatisticas.exibirModulo) {
        id_turma = this.fastTurma.id_turma
        id_programa_turma = this.fastAlunoModulo.id_programa_turma
      } else if (this.fastNavegacaoEstatisticas.exibirUnidade) {
        id_turma = this.fastTurma.id_turma
        id_programa_turma = this.fastAlunoModulo.id_programa_turma
        id_programa_turma_unidade = this.fastAlunoUnidade.id_programa_turma_unidade
      }


      // Evento ajax GET - busca todos os eventos do dia
      let ano = this.fastHistoricoDiaSelecionado.data_selecionada.split('/')[2]
      let mes = this.fastHistoricoDiaSelecionado.data_selecionada.split('/')[1]
      let dia = this.fastHistoricoDiaSelecionado.data_selecionada.split('/')[0]
      this.promiseGetFastApi("api/fast_secretaria_aluno_auditoria/lista_filtro", `id_usuario=${this.fastAluno.id_usuario}&id_plataforma=${this.$route.params.id_plataforma}&id_turma=${id_turma}&id_programa_turma=${id_programa_turma}&id_programa_turma_unidade=${id_programa_turma_unidade}&ano=${ano}&mes=${mes}&dia=${dia}`)
      .then((objArray) => {
        if (objArray.length) { 
          this.fastHistoricoDiaSelecionado.total = objArray
          this.fastHistoricoDiaSelecionado.filtro = objArray
          this.fastHistoricoDiaSelecionado.loading = false
        } else {
          this.fastHistoricoDiaSelecionado.loading = false
        }
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastHistoricoDiaSelecionado.loading = false
      })

      
    },
    //#endregion
    //#region Gabarito
    fileSuccessGabaritoAvaliacao(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.exibeToasty("Gabarito enviado com sucesso", "success")
        this.fastGabaritoAvaliacao = result.url
      } else {
        this.exibeToasty("Erro ao fazer upload", "error")
      }
    },
    fileSuccessGabaritoRecuperacao(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.exibeToasty("Gabarito enviado com sucesso", "success")
        this.fastGabaritoRecuperacao = result.url
      } else {
        this.exibeToasty("Erro ao fazer upload", "error")
      }
    },
    fileValidation(e) {
      return false;
    },
    //#endregion
    //#region Inconsistencias
    exibeModalExibirAulasReplicadas(aula){
      this.fastUsuarioCursoAulaExcluir.id_usuario_curso_aula = aula.id_usuario_curso_aula
      this.fastUsuarioCursoAulasReplicadas = []
      this.fastUsuarioCurso.total.forEach(a => {
        if (a.id_aula == aula.id_aula) this.fastUsuarioCursoAulasReplicadas.push(a)
      })
      this.$bvModal.show('modalExibirAulasReplicadas')

      console.log("this.fastUsuarioCursoAulasReplicadas", this.fastUsuarioCursoAulasReplicadas)
    },
    exibeModalExcluiAulaReplicada(aula){
      this.fastUsuarioCursoAulaExcluir.id_usuario_curso_aula = aula.id_usuario_curso_aula
      this.showModalBootstrap('modalExcluirAulaReplicada', 'modalExibirAulasReplicadas')
    },
    async excluirAulaReplicada(){
      this.promisePostFastApi(this.fastUsuarioCursoAulaExcluir, "api/fast_usuario_curso_aula/exclui_admin")
      .then((res) => {
        if (res.length) {
          this.exibeToasty("Erro ao excluir aula", "error")
        } else {          
          this.exibeToasty("Aula excluída com sucesso", "success")    
          const unidade = Object.assign({}, this.fastAlunoUnidade   ) 
          this.exibirDetalhesUnidade(unidade)
          
          // Busca porcentagem de progresso da UC principal
          this.getProgressoUnidade(this.fastAlunoUnidade.id_plataforma, this.fastAlunoUnidade.id_curso, this.fastAluno.id_usuario, "A")
          
          this.hideModalBootstrap('modalExcluirAulaReplicada')
        }          
      }).catch((e) => {
          this.exibeToasty("Erro ao excluir aula", "error")
      });
    },
    exibeModalExibirAtividadesReplicadas(atividade){
      this.fastUsuarioCursoAtividadeExcluir.id_usuario_atividade = atividade.id_usuario_atividade

      this.fastUsuarioCursoAtividadesReplicadas = []
      this.fastUsuarioCurso.total.forEach(a => {
        if (a.id_aula == atividade.id_aula) {
          console.log(a)
          if (a.atividades.total.length) {
            a.atividades.total.forEach(at => {
              if (atividade.id_atividade == at.id_atividade) this.fastUsuarioCursoAtividadesReplicadas.push(at)
            })
          }          
        }
      })
      this.$bvModal.show('modalExibirAtividadesReplicadas')

      console.log("this.fastUsuarioCursoAtividadesReplicadas", this.fastUsuarioCursoAtividadesReplicadas)
    },
    exibeModalExcluiAtividadeReplicada(atividade){
      this.fastUsuarioCursoAtividadeExcluir.id_usuario_atividade = atividade.id_usuario_atividade
      this.showModalBootstrap('modalExcluirAtividadeReplicada', 'modalExibirAtividadesReplicadas')
    },
    async excluirAtividadeReplicada(){
      const obj = {
        fast_usuario_curso_aula_atividade: this.fastUsuarioCursoAtividadeExcluir,
        id_plataforma: this.fastUsuarioCursoAtividadeExcluir.id_plataforma
      }
      this.promisePostFastApi(obj, "api/fast_usuario_curso_aula_atividade/exclui_admin")
      .then((res) => {
        if (res.length) {
          this.exibeToasty("Erro ao excluir atividade", "error")
        } else {          
          this.exibeToasty("Atividade excluída com sucesso", "success") 
          const unidade = Object.assign({}, this.fastAlunoUnidade   ) 
          this.exibirDetalhesUnidade(unidade)
          
          // Busca porcentagem de progresso da UC principal
          this.getProgressoUnidade(this.fastAlunoUnidade.id_plataforma, this.fastAlunoUnidade.id_curso, this.fastAluno.id_usuario, "A")
          
          this.hideModalBootstrap('modalExcluirAtividadeReplicada')
        }          
      }).catch((e) => {
          this.exibeToasty("Erro ao excluir atividade", "error")
      });
    },
    //#endregion
    //#region Boletim
    exibeBoletimAluno(aluno){
      if (!aluno.matricula_por_programa && !aluno.matricula_por_unidade) {
        // Traz todos os módulos
      } else if (aluno.matricula_por_programa) {
        // Traz somente os módulos que aluno está matriculado
      } else if (aluno.matricula_por_unidade) {
        // Traz somente os módulos em que existem unidades curriculares que o aluno está matriculado
      }
      this.fastAluno = aluno
      this.alteraFastNavegacao('exibirBoletim')
      
      this.fastAlunoModulos.loading = true
      this.fastAlunoModulos.total = []
      this.promiseGetFastApi("api/fast_secretaria_turma_programa/lista_aluno", "id_plataforma=" + aluno.id_plataforma + "&id_matricula=" + aluno.id_matricula + "&id_turma=" + aluno.id_turma)
      .then((res) => {
        if (res.length) {
          this.fastAlunoModulos.total = res    
          this.boletim_modulos_selecionados = res        
        } 
        this.fastAlunoModulos.loading = false
      })
      .catch((e) => {
        this.exibeToasty("Erro", "error");
        this.fastAlunoModulos.loading = false
      });
        
    },
    exibeModalBoletim(){      
      let erros = []
      let contrato = this.boletim_html_template
      
      //console.log("this.fastTurma", this.fastTurma)

      if (!this.boletim_modulos_selecionados.length) {
        this.exibeToasty("Selecione um módulo", "error")
      } else {
        this.$store.state.fastCarregando = true
        this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", "id_pessoa=" + this.fastAluno.id_pessoa_aluno + "&id_plataforma=" + this.$route.params.id_plataforma)
        .then(obj => {       
          let objPessoa = JSON.parse(obj)
          //console.log("objPessoa", objPessoa)
          if (objPessoa.id_pessoa) {    
              if (this.boletim_html_template.indexOf("%nome_aluno%") != "-1" && !objPessoa.nome_razao) erros.push("O nome do aluno não foi cadastrado")
              if (this.boletim_html_template.indexOf("%nome_aluno%") != "-1" && !objPessoa.sobrenome_fantasia) erros.push("O sobrenome do aluno não foi cadastrado")
              if (this.boletim_html_template.indexOf("%cpf_aluno%") != "-1" && !objPessoa.cpf_cnpj) erros.push("O CPF do aluno não foi cadastrado")                 
              if (this.boletim_html_template.indexOf("%naturalidade_aluno%") != "-1" && !objPessoa.cidade) erros.push("O cidade do aluno não foi cadastrada")
              if (this.boletim_html_template.indexOf("%naturalidade_aluno%") != "-1" && !objPessoa.uf) erros.push("O estado do aluno não foi cadastrado")
              if (this.boletim_html_template.indexOf("%nascimento_aluno%") != "-1" && !objPessoa.datanascimento_abertura) erros.push("A data de nascimento do aluno não foi cadastrada")
              if (this.boletim_html_template.indexOf("%nome_curso%") != "-1" && !this.fastTurma.nome_curso) erros.push("O nome do curso não foi identificado")

              if (!erros.length) {
                this.toDataUrl(this.$store.state.fastPlataforma.logo_plataforma, function(myBase64) {
                    contrato = contrato.replaceAll(
                    "%img_logo_plataforma%",
                      `<img src="${myBase64}" class="mw-100">`
                    )
                })

                contrato = contrato.replaceAll(
                "%nome_aluno%",
                  objPessoa.nome_razao.trim() + " " + objPessoa.sobrenome_fantasia.trim()
                )
                contrato = contrato.replaceAll(
                  "%cpf_aluno%",
                  objPessoa.cpf_cnpj.trim()
                )  
                contrato = contrato.replaceAll(
                  "%naturalidade_aluno%",
                  objPessoa.cidade.trim() + "/" + objPessoa.uf.trim()
                ) 
                contrato = contrato.replaceAll(
                  "%nascimento_aluno%",
                  this.formataDataT(objPessoa.datanascimento_abertura.trim())
                )  
                contrato = contrato.replaceAll(
                  "%nacionalidade_aluno%",
                  'Brasileira'
                )  
                contrato = contrato.replaceAll(
                  "%nome_curso%",
                  this.fastTurma.nome_curso
                )  
                if (this.fastTurma.nivel) {
                  contrato = contrato.replaceAll(
                    "%nivel_curso%",
                    this.retornaCursoNivel(this.fastTurma.nivel)
                  )  
                } else {
                  contrato = contrato.replaceAll(
                    "%nivel_curso%",
                    ''
                  )  
                }

                if (this.fastTurma.descricao_legal) {
                  contrato = contrato.replaceAll(
                    "%descricao_legal%",
                    this.fastTurma.descricao_legal.trim()
                  )  
                } else {
                  contrato = contrato.replaceAll(
                    "%descricao_legal%",
                    ''
                  )  
                }

                // Dados das unidades
                let html_tbody_boletim = ``
                this.boletim_modulos_selecionados.forEach((m, index) => {
                  html_tbody_boletim += `<tr><td colspan="7"><b>${m.sigla_programa}</b></td></tr><tr>%unidades_modulo_${m.id_programa_turma}%</tr>`
                })
                this.boletim_modulos_selecionados.forEach((m, index) => {
                  this.getPromiseUnidadesModulo(m.id_programa_turma)
                  .then(unidades => {
                    //console.log("unidades", unidades)

                    let html_unidades_boletim = ``
                    if (unidades.length) {
                      unidades.forEach(u => {
                        html_unidades_boletim += `<tr>`
                        html_unidades_boletim += `<td>${u.data_fim_avaliacao ? u.data_fim_avaliacao.split("-")[0] : '-'}</td><td>${u.nome_curso ? u.nome_curso : u.nome_curso_mae}</td><td>-</td><td>-</td><td>${u.carga_horaria ? u.carga_horaria : u.carga_horaria_mae}h</td>` 
                        let aprovado = "REPROVADO"
                        let nota = "-"
                        if (u.avaliacao_configurada) {   
                          nota = u.nota_final_avaliacao

                          if (u.aprovado_avaliacao) { 
                            aprovado = "APROVADO"                            
                          } else if (u.recuperacao_configurada) {
                            if (u.aprovado_recuperacao) {
                              aprovado = "APROVADO"
                              nota = u.nota_final_recuperacao
                            } else {
                              if (u.nota_final_recuperacao > u.nota_final_avaliacao) nota = u.nota_final_recuperacao
                            } 
                          } 
                        } else {
                          aprovado = "-"
                        }
                        html_unidades_boletim += `<td>${nota ? nota : '-'}</td>`  
                        html_unidades_boletim += `<td>${aprovado}</td>` 
                        html_unidades_boletim += `</tr>`  
                      })
                    }
                    

                    html_tbody_boletim = html_tbody_boletim.replaceAll(
                      `%unidades_modulo_${m.id_programa_turma}%`,
                      html_unidades_boletim
                    ) 
                    // console.log(`Substituindo %unidades_modulo_${m.id_programa_turma}%`, html_tbody_boletim)

                    if (!html_tbody_boletim.includes('%unidades_modulo_')) {
                      console.log("Carregou último módulo")

                      contrato = contrato.replaceAll(
                        "%tbody_boletim%",
                        html_tbody_boletim
                      ) 

                      console.log("contrato", contrato)

                      // Gera pdf
                      this.boletim_html_template = contrato;
                      this.$refs.html2Pdf.generatePdf()
                      this.$store.state.fastCarregando = false
                    } 

                    
                  })
                  .catch(e => {
                    this.exibeToasty(e, "error")
                    console.log(e)
                    this.$store.state.fastCarregando = false
                  })
                })
                
              } else {                    
                erros.forEach(e => this.exibeToasty(e, "error"))
                this.$store.state.fastCarregando = false;
              }  
          } else {
            this.exibeToasty("Erro ao buscar dados do aluno", "error");
            this.$store.state.fastCarregando = false;
          }
        }).catch(e => {
          console.log(e);
          this.exibeToasty("Erro ao buscar dados do aluno", "error");
          this.$store.state.fastCarregando = false;
        })
      }
      
      
      //this.showModalBootstrap('modalExibirBoletimTurma')
    },
    checkModuloBoletim(modulo) {
      let boletim_modulos_selecionados = this.boletim_modulos_selecionados.filter(e => e.id_programa_turma == modulo.id_programa_turma)
      if (!boletim_modulos_selecionados.length) this.boletim_modulos_selecionados.push(modulo)
      else
        this.boletim_modulos_selecionados = this.boletim_modulos_selecionados.filter(e => e.id_programa_turma != modulo.id_programa_turma)

      console.log("this.boletim_modulos_selecionados", this.boletim_modulos_selecionados)
    },
    async getPromiseUnidadesModulo(id_programa_turma) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_turma_programa/lista_aluno_unidades?id_plataforma=" +  this.$route.params.id_plataforma + "&id_matricula=" + this.fastAluno.id_matricula + "&id_programa_turma=" + id_programa_turma, this.fastAjaxOptions("GET"));
          let json = await resp.json();          
          resolve(json);
          
        } catch (e) {
          reject(e);
        }
      });

    },
    toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
    //#endregion
  },
};
</script>

<style scope>
.fast-cursor-pointer:hover {
  background-color: #eee;
}
.fast-cursor-refresh {
  cursor: pointer;
}
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
/* Modal de respostas da avlaiação */
.fast-aula-alternativa-escolhida {
  background-color: rgb(192, 192, 192);
}
.fast-aula-alternativa-escolhida-correta {
  background-color: rgb(82, 255, 97);
}
.fast-aula-alternativa-escolhida-incorreta {
  background-color: rgb(255, 58, 58);
}

/* Calendário */
.cal-feriado-marcado {
  background-color: #E10000 !important;
  color: #fff;
}
.cal-turma-marcado {
  background-color: #00e131 !important;
  color: #fff;
  cursor: pointer;
}
.cal-turma-conflito-marcado, .cal-feriado-conflito-marcado  {
  background-color: #fdba01 !important;
  color: #fff;
}
.fast-cursor-pointer:hover {
  background-color: #eee;
}
.fast-ano-selecionado {
  background-color: #eee;
}
.vfc-disabled {
  opacity: 0;
}
</style>
